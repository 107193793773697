<template>
  <div>
    <img src="@/assets/solution/img7.png" class="banner" />
    <div class="content">
      <div class="title">方案概述</div>
      <div class="sub">
        智慧交通解决方案是为道路交通事故自动化设障的软硬件智能系统，系统由交通故障指挥平台、道路监控云台和交通设障机器人三部分组成，采用4G通讯网络、高精度定位技术、远程监控技术和智能控制技术，通过监控云台报送事故点信息到交通故障指挥平台，平台调度指挥设障机器人为事故现场自动化设置围栏，保障司乘人员和车辆安全。
      </div>
    </div>
    <img src="@/assets/solution/img8.png" class="blueprint" />
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.banner {
  width: 100%;
  height: 46.25rem;
}
.title {
  font-size: 3.25rem;
  font-weight: bold;
  color: #333333ff;
  text-align: left;
}
.content {
  width: 100%;
  padding: 8.75rem 0 8.75rem 9.125rem;
  box-sizing: border-box;
}
.sub {
  width: 101.25rem;
  margin-top: 2.75rem;
  font-size: 1.25rem;
  text-align: left;
}
.blueprint {
  width: 100%;
  height: 64.25rem;
}
</style>