<template>
  <div>
    <div class="detail">
      <img src="@/assets/product/at31/img1.png" class="detail_img" />
      <div :class="['detail_text', 'gradient_right']">
        <div class="detail_title">AT31</div>
        <div class="detail_title">双屏⼈证核验终端</div>
      </div>
      <div :class="['detail_tab', show1 ? 'gradient_right' : '']">
        <div class="detail_single" v-for="item in category" :key="item.name">
          <img :src="item.img" class="detail_single_icon" />
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/at31/img2.png"
        class="detail_img"
        ref="targetElement"
      />
      <div :class="['detail_text2', show2 ? 'gradient_left' : '']">
        <div class="detail_title">多证可读</div>
        <div class="detail_sub2">
          支持读取⾝份证、多种智能卡，进行身份采集完成身份信息认证
        </div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/at31/img3.png"
        class="detail_img"
        ref="targetElement2"
      />
      <div :class="['detail_text3', show3 ? 'gradient_top' : '']">
        <div class="detail_title">快速扫码 精准识别</div>
        <div class="detail_sub2">
          出示二维码，配合人像采集，提交认证信息可快速完成认证
        </div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/at31/img4.png"
        class="detail_img"
        ref="targetElement3"
      />
      <div :class="['detail_text4', show4 ? 'gradient_left' : '']">
        <div class="detail_title">高效指纹采集</div>
        <div class="detail_sub2">高性能指纹模组，易集成更安全</div>
      </div>
    </div>
    <div class="parameter">
      <div class="parameter_title">产品参数</div>
      <div v-for="(item, index) in argumentsList" :key="index">
        <div class="parameter_tab">{{ item.name }}</div>
        <div
          class="parameter_item"
          v-for="citem in item.list"
          :key="citem.name"
        >
          <div class="parameter_item_title">{{ citem.name }}</div>
          <div class="parameter_item_content">{{ citem.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
import icon1 from "../../assets/product/at31/icon1.png";
import icon2 from "../../assets/product/at31/icon2.png";
import icon3 from "../../assets/product/at31/icon3.png";
import icon4 from "../../assets/product/at31/icon4.png";
import icon5 from "../../assets/product/at31/icon5.png";
import icon6 from "../../assets/product/at31/icon6.png";
export default {
  data() {
    return {
      category: [
        {
          name: "多证读取",
          img: icon1,
        },
        {
          name: "快速扫码",
          img: icon2,
        },
        {
          name: "活体检测",
          img: icon3,
        },
        {
          name: "指纹采集",
          img: icon4,
        },
        {
          name: "数据追溯",
          img: icon5,
        },
        {
          name: "可定制化",
          img: icon6,
        },
      ],
      argumentsList: [
        {
          name: "常规参数",
          list: [
            {
              name: "产品名称与型号",
              content: "双屏⼈证核验终端AT31",
            },
            {
              name: "系统",
              content: "Android 7.1",
            },
            {
              name: "处理器",
              content: "RK3288，四核ARM Cortex A17 1.8 GHz",
            },
            {
              name: "存储",
              content: "4G+32G（TF卡槽，⽀持最⼤128G）（可选）",
            },
            {
              name: "WIFI",
              content: "2.4G⽀持Wi-Fi 802.11b/g/n",
            },
          ],
        },
        {
          name: "屏幕",
          list: [
            {
              name: "尺⼨",
              content: "10.1+10.1",
            },
            {
              name: "分辨率",
              content: "1280*800+1280*800",
            },
            {
              name: "触摸",
              content: "单触或双触选择",
            },
          ],
        },
        {
          name: "摄像头",
          list: [
            {
              name: "类型",
              content: "RGB:CMOS 1/2.7”IR:CMOS 1/2.7”",
            },
            {
              name: "分辨率",
              content: "200万像素",
            },
            {
              name: "光圈",
              content: "F2.2",
            },
            {
              name: "焦距",
              content: "2.88MM",
            },
            {
              name: "⽩平衡",
              content: "⾃动",
            },
            {
              name: "宽动态",
              content: "⽀持",
            },
          ],
        },
        {
          name: "身份证模块",
          list: [
            {
              name: "可读卡型",
              content: "⼆代⾝份证(本地解码，公安部认证)",
            },
            {
              name: "阅读距离",
              content: "0~3MM",
            },
            {
              name: "读卡响应速度",
              content: "＜1s",
            },
          ],
        },
        {
          name: "指纹模块 （选配）",
          list: [
            {
              name: "传感器类型",
              content: "电容、活体、面状",
            },
            {
              name: "图像分辨率",
              content: "508dpi",
            },
            {
              name: "图像⼤⼩",
              content: "256*360Pixel(W*H)",
            },
          ],
        },
        {
          name: "扫码窗 （选配）",
          list: [
            {
              name: "⽀持类别",
              content:
                "⼀维码：EAN-8、EAN-13、ISBN-10、ISBN-13、CODE39、 CODE93、CODE128、UPC、ITF、Code Bar，⼆维码：QRCODE、PDF417等",
            },
            {
              name: "扫码距离",
              content: "2-15cm",
            },
            {
              name: "识别速度",
              content: "15次/s Max",
            },
          ],
        },
        {
          name: "打印机 （选配）",
          list: [
            {
              name: "打印⽅式",
              content: "热敏⾏点式打印",
            },
            {
              name: "分辨率",
              content: "203dpi(8dots/mm)",
            },
            {
              name: "打印速度",
              content: "Max:90mm/s",
            },
          ],
        },
      ],
      show1: false,
      show2: false,
      show3: false,
      show4: false,
    };
  },

  created() {
    setTimeout(() => {
      this.show1 = true;
    }, 1000);
  },

  directives: {
    ScrollTo: VueScrollTo.directive,
  },
  mounted() {
       this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    // 获取页面滚动距离
    handleScroll() {
      const element = this.$refs.targetElement;
      const element2 = this.$refs.targetElement2;
      const element3 = this.$refs.targetElement3;

      const rect = element.getBoundingClientRect();
      const rect2 = element2.getBoundingClientRect();
      const rect3 = element3.getBoundingClientRect();

      if (rect.top <= window.innerHeight && rect.bottom <= 1500) {
        // 元素在可视区域内
        if (!this.show2) {
          this.show2 = true;
        }
      }

      if (rect2.top <= window.innerHeight && rect2.bottom <= 1500) {
        if (!this.show3) {
          this.show3 = true;
        }
      }
      if (rect3.top <= window.innerHeight && rect3.bottom <= 1500) {
        if (!this.show4) {
          this.show4 = true;
        }
      }
    },
  },
};
</script>

<style  scoped>
.detail {
  width: 100%;
  height: 67.5rem;
  position: relative;
}
.detail_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.detail_text {
  top: 22.8125rem;
  right: 12.5rem;
  position: absolute;
  color: white;
  opacity: 0;
}
.detail_title {
  font-size: 5rem;
  font-weight: bold;
  text-align: left;
}
.detail_sub {
  font-size: 2rem;
  margin-top: 1.25rem;
  text-align: left;
}
.detail_tab {
  position: absolute;
  opacity: 0;
  bottom: 9.75rem;
  right: 21.25rem;
  width: 31.25rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.detail_single {
  display: flex;
  align-items: center;
  flex: 0 0 48%;
  font-size: 1.5rem;
  color: white;
  margin-bottom: 2.5rem;
}
.detail_single_icon {
  width: 2.75rem;
  height: 2.75rem;
  margin-right: 1rem;
}
.detail_text2 {
  position: absolute;
  left: 9.125rem;
  opacity: 0;
  top: 27.6875rem;
  color: #000000ff;
}
.detail_text3 {
  width: 100%;
  color: white;
  opacity: 0;
  position: absolute;
  top: 7.5rem;
}
.detail_text3 div {
  width: 100%;
  text-align: center;
}
.detail_sub2 {
  width: 32.5rem;
  font-size: 1.375rem;
  margin-top: 1.25rem;
  text-align: left;
}
.detail_text4 {
  position: absolute;
  opacity: 0;
  left: 9.125rem;
  top: 28.9375rem;
  color: #000000ff;
}
.parameter_title {
  width: 100%;
  line-height: 13.75rem;
  height: 13.75rem;
  padding-left: 9.125rem;
  box-sizing: border-box;
  font-size: 3.75rem;
  text-align: left;
  color: #000000ff;
  font-weight: bold;
}
.parameter_tab {
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  padding-left: 8.75rem;
  box-sizing: border-box;
  background: #fafafa;
  text-align: left;
  font-size: 1.75rem;
  color: #000000ff;
}
.parameter_item {
  width: 101.75rem;
  margin: 0 auto;
  border-bottom: .0625rem solid #F5F5F5FF;
  display: flex;
  justify-content: flex-start;
  min-height: 4.5rem;
  align-items: center;
}
.parameter_item_title {
  width: 29.625rem;
  font-size: 1.25rem;
  color: #797979ff;
  text-align: left;
}
.parameter_item_content {
  max-width: 71.75rem;
  font-size: 1.25rem;
  color: #333333ff;
  text-align: left;
}
</style>