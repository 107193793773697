<template>
  <div>
    <img src="@/assets/solution/img3.png" class="banner" />
    <div class="content">
      <div class="title">方案概述</div>
      <div class="sub">
        智慧校园解决方案基于学校现有监控建设，充分利用已有摄像头、新增门禁终端、电子学生证，对监控内容进行AI赋能，通过对摄像头采集到的图像的分析和识别，门禁通行与控制，个人轨迹跟踪，来满足孩、家、校特定管理和安全功能需求的智慧校园系统。
      </div>
    </div>
    <img src="@/assets/solution/img4.png" class="blueprint" />
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.banner {
  width: 100%;
  height: 46.25rem;
}
.title {
  font-size: 3.25rem;
  color: #333333ff;
  font-weight: bold;
  text-align: left;
}
.content {
  width: 100%;
  padding: 8.75rem 0 8.75rem 9.125rem;
  box-sizing: border-box;
}
.sub {
  width: 101.25rem;
  margin-top: 2.75rem;
  font-size: 1.25rem;
  text-align: left;
}
.blueprint {
  width: 100%;
  height: 50.25rem;
}
</style>