<template>
  <div>
    <div class="detail">
      <img src="@/assets/product/pda/img1.png" class="detail_img" />
      <div :class="['detail_text', 'gradient_right']">
        <div class="detail_title4">证码通RC20</div>
        <div class="detail_title4">证码核验手持移动终端</div>
        <div class="detail_sub4">考勤管理 | 访客管理 | 电子门禁</div>
      </div>
    </div>

    <div class="detail">
      <img
        src="@/assets/product/pda/img2.png"
        class="detail_img"
        ref="targetElement"
      />
      <div :class="['detail_text2', show1 ? 'gradient_left' : '']">
        <div class="detail_title">多证可读</div>
        <div class="detail_sub2">
          支持读取ISO/IEC
          14443A、14443B、Mifare等非接触卡TypeA卡/TypeB卡及电子标签，包含身份证、港澳台居民居住证、外国人永久居留证、公交卡、市政卡、老年卡、学生卡、护照、elD电子证件等
        </div>

        <div class="detail_tab">
          <div>身份证</div>
          <div>港澳台居民居住证</div>
          <div>外国人永久居留证</div>
          <div>护照</div>
          <div>eID电子证件</div>
          <div>老年卡</div>
          <div>市政卡</div>
          <div>公交卡</div>
          <div>学生卡</div>
        </div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/pda/img3.png"
        class="detail_img"
        ref="targetElement2"
      />
      <div :class="['detail_text3', show2 ? 'gradient_top' : '']">
        <div class="detail_title3">快速扫码 0.3s精准识别</div>
        <div class="detail_sub3">
          可识别一维码：UPC/EAN, Code128, Code39等
          <div>可识别二维码：QRCode、PDF417等</div>
        </div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/pda/img4.png"
        class="detail_img"
        ref="targetElement3"
      />
      <div :class="['detail_text4', show3 ? 'gradient_left' : '']">
        <div class="detail_title4">开放接口<br />支持应用扩展</div>
        <div class="detail_sub4">可接入门禁通道、考勤系统等第三方系统</div>
      </div>
    </div>

    <div class="parameter">
      <div class="parameter_title">产品参数</div>
      <div v-for="(item, index) in argumentsList" :key="index">
        <div class="parameter_tab">{{ item.name }}</div>
        <div
          class="parameter_item"
          v-for="citem in item.list"
          :key="citem.name"
        >
          <div class="parameter_item_title">{{ citem.name }}</div>
          <div class="parameter_item_content">{{ citem.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
export default {
  data() {
    return {
      argumentsList: [
        {
          name: "常规参数",
          list: [
            {
              name: "产品名称与型号",
              content: "证码通手持移动终端RC20",
            },
            {
              name: "CPU",
              content: "Qualcomm 八核 1.8G 主频",
            },
            {
              name: "OS版本",
              content: "Android 9.0",
            },
            {
              name: "存储容量",
              content: "RAM+ROM:2GB+16GB",
            },
            {
              name: "扩展存储",
              content: "MicroSD(TF)卡可扩展至128GB",
            },
            {
              name: "尺寸",
              content: "219mm（长）*111mm（宽）*21.5mm（高）",
            },
            {
              name: "定位导航",
              content:
                "集成 GPS、北斗、GLONASS，内置天线，支持 AGPS，精度 10 米以内",
            },
            {
              name: "电池容量",
              content: "6000mAh",
            },
            {
              name: "待机时长",
              content: "满电待机>8小时",
            },
            {
              name: "整机尺寸",
              content: "170mm x 79mm x 22.6mm",
            },
            {
              name: "整机重量",
              content: "小于 300g （依照配置不同有区别）",
            },
          ],
        },
        {
          name: "网络",
          list: [
            {
              name: "网络类型",
              content: "欧亚+国内",
            },
            {
              name: "移动网络",
              content: "支持 4G 全网通（电信 4G、移动 4G、联通 4G）",
            },
            {
              name: "WLAN功能",
              content: "IEEE 802.11a/b/g/n，支持双频 2.4G 和 5G",
            },
            {
              name: "SIM卡",
              content: "支持双SIM卡",
            },
            {
              name: "卡槽类型",
              content: "两个Nano-SIM卡和一个 TF卡",
            },
            {
              name: "TF卡",
              content: "支持（SIM卡与TF二选一）",
            },
          ],
        },
        {
          name: "NFC",
          list: [
            {
              name: "工作频率",
              content: "13.56MHz",
            },
            {
              name: "读卡类型",
              content:
                "ISO/IEC 14443A、14443B、Mifare等非接触卡TypeA卡/TypeB卡及电子标签，包含身份证、港澳台居民居住证、外国人永久居留证、公交卡、市政卡、老年卡、学生卡、护照、elD电子证件等",
            },
            {
              name: "读写距离",
              content: "0-3cm",
            },
          ],
        },
        {
          name: "LCM",
          list: [
            {
              name: "LCD",
              content: "5.5 英寸 IPS 高清屏，HD 分辨率（720*1440）",
            },
            {
              name: "TP",
              content:
                "电容屏，多点触摸，支持湿手、手套操作，采用防油渍指纹涂层",
            },
          ],
        },
        {
          name: "数据采集",
          list: [
            {
              name: "摄像头",
              content: "后置1600万像素彩色摄像头，人脸识别距离：0.2-2m",
            },
            {
              name: "条码采集",
              content:
                "支持一维码条码类型：UPC/EAN, Code128, Code39, Code93, Code11, Interleaved 2 of 5, Discrete 2 of 5, Chinese2 of 5, Codabar, MSI, RSS等；支持二维码条码类型：Code39，Code128，PDF417, MicroPDF417, Datamatrix,Composite, RSS, TLC-39, QR code, Micro QR code, Aztec, MaxiCode; Postal Codes;US Planet,UK Postal,Australian Postal,Japan Postal等 ",
            },
          ],
        },
        {
          name: "使用环境",
          list: [
            {
              name: "工作温度",
              content: "-10°C 至 50°C",
            },
            {
              name: "储存温度",
              content: "-40°C 至 70°C",
            },
            {
              name: "环境温度",
              content: "5%RH - 95%RH （无凝结）",
            },
          ],
        },
      ],
      show1: false, //是否显示
      show2: false, //是否显示
      show3: false, //是否显示
    };
  },
  directives: {
    ScrollTo: VueScrollTo.directive,
  },
  mounted() {
        this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 获取页面滚动距离
    handleScroll() {
      const element = this.$refs.targetElement;
      const element2 = this.$refs.targetElement2;
      const element3 = this.$refs.targetElement3;
      const rect = element.getBoundingClientRect();
      const rect2 = element2.getBoundingClientRect();
      const rect3 = element3.getBoundingClientRect();

      if (rect.top <= window.innerHeight && rect.bottom <= 1500) {
        if (!this.show1) {
          this.show1 = true;
        }
      }

      if (rect2.top <= window.innerHeight && rect2.bottom <= 1500) {
        if (!this.show2) {
          this.show2 = true;
        }
      }
      if (rect3.top <= window.innerHeight && rect3.bottom <= 1500) {
        if (!this.show3) {
          this.show3 = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.detail {
  width: 100%;
  height: 67.5rem;
  position: relative;
}
.detail5 {
  width: 100%;
  height: 93.9375rem;
  position: relative;
}
.detail_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.detail_text {
  color: white;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 24.75rem;
  left: auto;
  right: 9.125rem;
  opacity: 0;
}
.detail_title {
  font-size: 5rem;
  font-weight: bold;
  text-align: left;
  color: #000000;
}
.detail_sub {
  font-size: 2rem;
  text-align: left;
  margin-top: 3.75rem;
}
.detail_bottom {
  margin-top: 12.75rem;
  left: 7.625rem;
  display: flex;
  width: 43.75rem;
  flex-wrap: wrap;
  justify-content: space-between;
}
.detail_bottom_item {
  width: 12.5rem;
  height: 5.25rem;
  display: flex;
  justify-content: center;
}
.detail_img_item {
  width: 2.75rem;
  height: 2.75rem;
}
.detail_bottom_text {
  height: 2.75rem;
  line-height: 2.75rem;
  margin-left: 0.625rem;
  font-size: 1.5rem;
}
.detail_content2 {
  padding-top: 12.125rem;
  padding-left: 9.125rem;
  padding-right: 9.125rem;
  display: flex;
  justify-content: space-between;
}
.detail_item2 {
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.detail_img_item2 {
  width: 27.375rem;
  height: 23.75rem;
}
.detail_item_text2 {
  font-size: 3rem;
  margin-top: 5.5rem;
  margin-bottom: 3.375rem;

  font-weight: bold;
}
.detail_bottom_text2 {
  font-size: 1.375rem;
  width: 17.5rem;
}
.detail_sub2 {
  font-size: 1.375rem;
  text-align: left;
  margin-top: 1.25rem;
  width: 49.625rem;
  line-height: 2.375rem;
  color: #333333;
}
.detail_text2 {
  position: absolute;
  top: 10.3125rem;
  left: 9.125rem;
  opacity: 0;
}
.detail_tab {
  display: flex;
  width: 41.375rem;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 13.125rem;
}
.detail_tab div {
  flex: 0 0 50%;
  text-align: left;
  box-sizing: border-box;
  padding-left: 1.5625rem;
  font-size: 1.25rem;
  color: #333333ff;
  position: relative;
  margin-bottom: 2.5rem;
}
.detail_tab div::after {
  content: "";
  background: #333333ff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  width: 0.875rem;
  height: 0.875rem;
}
.detail_text3 {
  position: absolute;
  color: #000000;
  width: 100%;
  text-align: center;
  opacity: 0;
}

.detail2 {
  width: 100%;
  height: 81.5625rem;
  position: relative;
}
.detail_title3 {
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 7.5rem;
}
.detail_sub3 {
  font-size: 1.375rem;
  margin-top: 1.25rem;
}
.detail_title4 {
  font-size: 5rem;
  font-weight: bold;
  text-align: left;
}
.detail_sub4 {
  font-size: 1.375rem;
  text-align: left;
  margin-top:1.25rem;
}
.detail_text4 {
  position: absolute;
  top: 25.4375rem;
  left: 9.125rem;
  color: #ffffff;
  opacity: 0;
}

.table-second {
  width: 100%;
  height: 5rem;
  background: #fafafa;
  padding-left: 9.125rem;
  font-size: 1.75rem;
  line-height: 5rem;
  color: #000000;
}
.table-third {
  width: auto;
  margin-left: 9.125rem;
  margin-right: 9.125rem;
  display: flex;
}
.table-item {
  min-height: 4.5rem;
  line-height: 4.5rem;
  color: #797979;
  font-size: 1.25rem;
  border-bottom: 0.0625rem solid #f5f5f5;
}
.table_left {
  width: 30%;
}
.table-right {
  width: 70%;
}
.table_left_item {
  height: 4.5rem;
  line-height: 4.5rem;
  border-bottom: 0.0625rem solid #f5f5f5;
}
.table_left_item2 {
  height: 6.625rem !important;
  line-height: 6.625rem;
}
.table_right_item {
  height: 6.625rem;
  border-bottom: 0.0625rem solid #f5f5f5;
  vertical-align: middle;
  display: table-cell;
}
/* 产品参数 */
.parameter_title {
  width: 100%;
  line-height: 13.75rem;
  height: 13.75rem;
  padding-left: 9.125rem;
  box-sizing: border-box;
  font-size: 3.75rem;
  text-align: left;
  color: #000000ff;
  font-weight: bold;
}
.parameter_tab {
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  padding-left: 8.75rem;
  box-sizing: border-box;
  background: #fafafa;
  text-align: left;
  font-size: 1.75rem;
  color: #000000ff;
}
.parameter_item {
  width: 101.75rem;
  margin: 0 auto;
  border-bottom: 0.0625rem solid #f5f5f5ff;
  display: flex;
  justify-content: flex-start;
  min-height: 4.5rem;
  align-items: center;
}
.parameter_item_title {
  width: 29.625rem;
  font-size: 1.25rem;
  color: #797979ff;
  text-align: left;
}
.parameter_item_content {
  max-width: 71.75rem;
  font-size: 1.25rem;
  color: #333333ff;
  text-align: left;
}
</style>
