<template>
  <div>
    <div class="detail">
      <img src="@/assets/product/at21/img1.png" class="detail_img" />
      <div :class="['detail_text', 'gradient_right']">
        <div class="detail_title">AT-21</div>
        <div class="detail_title">智能发卡收银一体机</div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/at21/img2.png"
        class="detail_img"
        ref="targetElement"
      />
      <div :class="['detail_text2', show1 ? 'gradient_top' : '']">
        <div class="detail_title2">高清人脸识别</div>
        <div class="detail_sub2">
          搭载高清前置摄像头，支持高清人脸识别、人证比对
        </div>

        <div class="detail_tab">
          <div class="dimension">高清前置摄像头</div>
          <div class="div1"></div>
          <div class="div2"></div>
        </div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/at21/img3.png"
        class="detail_img"
        ref="targetElement2"
      />
      <div :class="['detail_text3', show2 ? 'gradient_right' : '']">
        <div class="detail_title">多身份识别</div>
        <div class="dimension3">支持读取多种证件识别身份信息</div>
        <div class="detail_tab3">
          <div>居民身份证</div>
          <div>港澳台居民居住证</div>
          <div>外国人永久居留证</div>
          <div>护照</div>
        </div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/at21/img4.png"
        class="detail_img"
        ref="targetElement3"
      />
      <div :class="['detail_text4', show3 ? 'gradient_top' : '']">
        <div class="detail_title2">高速打印小票</div>
        <div class="detail_sub2">可打印收银小票、凭条、业务流水票据等</div>
      </div>
    </div>
    <div class="parameter">
      <div class="parameter_title">产品参数</div>
      <div v-for="(item, index) in argumentsList" :key="index">
        <div class="parameter_tab">{{ item.name }}</div>
        <div
          class="parameter_item"
          v-for="citem in item.list"
          :key="citem.name"
        >
          <div class="parameter_item_title">{{ citem.name }}</div>
          <div class="parameter_item_content">{{ citem.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
export default {
  data() {
    return {
      argumentsList: [
        {
          name: "常规参数",
          list: [
            {
              name: "产品名称与型号",
              content: "智能发卡收银一体机AT21",
            },
            {
              name: "屏幕",
              content: "10.1英寸屏幕，分辨率为1280x800，支持多点触摸",
            },
            {
              name: "CPU",
              content: "ARM Cortex-A17四核1.8GHz",
            },
            {
              name: "操作系统",
              content: "Android 7.1 SrTi安全操作系统",
            },
            {
              name: "内存",
              content: "RAM：2GB+ROM：8GB          RAM：2GB+ROM: 16GB (可选)",
            },
            {
              name: "Wi-Fi",
              content: "WIFI 2.4GHz支持IEEE 802.11 b/g/n",
            },
            {
              name: "摄像头",
              content:
                "双500万摄像头；前置摄像头：用于人脸识别；扫码摄像头：支持条形码、二维码",
            },
            {
              name: "二维码读取速度",
              content: "200次/秒",
            },
            {
              name: "身份证读取速度",
              content: "<2秒",
            },
            {
              name: "NFC",
              content: "可读身份证、读取护照",
            },
            {
              name: "发卡模块",
              content:
                "新卡槽可容纳150张，废卡槽可容纳20张支持卡协议:ISO7816接触卡标准，ISO14443非接触卡标准",
            },
            {
              name: "接口",
              content: "DC电源接口、USB TYPE-A、USB TYPE-B、DVI接口、RJ-45",
            },
            {
              name: "打印机",
              content: "58热敏打印机",
            },
            {
              name: "电源",
              content: "适配器供电，AC 100V ~ 220V/2A ~ DC 24V/2.7A DC",
            },
            {
              name: "以太网",
              content: "RJ45接口，10/100M",
            },
            {
              name: "工作环境",
              content: "工作温度: 0°C～40°C，存储温度: -20°C～60°C",
            },
            {
              name: "尺寸",
              content: "400mm（长）*300mm（宽）*324mm（高）",
            },
            {
              name: "重量",
              content: "3.6kg",
            },
          ],
        },
      ],
      show1: false, //是否显示
      show2: false, //是否显示
      show3: false, //是否显示
    };
  },
  directives: {
    ScrollTo: VueScrollTo.directive,
  },
  mounted() {
        this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 获取页面滚动距离
    handleScroll() {
      const element = this.$refs.targetElement;
      const element2 = this.$refs.targetElement2;
      const element3 = this.$refs.targetElement3;
      const rect = element.getBoundingClientRect();
      const rect2 = element2.getBoundingClientRect();
      const rect3 = element3.getBoundingClientRect();

      if (rect.top <= window.innerHeight && rect.bottom <= 1500) {
        if (!this.show1) {
          this.show1 = true;
        }
      }

      if (rect2.top <= window.innerHeight && rect2.bottom <= 1500) {
        if (!this.show2) {
          this.show2 = true;
        }
      }
      if (rect3.top <= window.innerHeight && rect3.bottom <= 1500) {
        if (!this.show3) {
          this.show3 = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.detail {
  width: 100%;
  height: 67.5rem;
  position: relative;
}
.detail_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.detail_text {
  color: #000000;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 23.3125rem;
  left: 9.125rem;
  opacity: 0;
}
.detail_title {
  font-size: 5rem;
  font-weight: bold;
  text-align: left;
}
.detail_sub {
  font-size: 2rem;
  text-align: left;
  margin-top: 3.75rem;
}
.detail_title2 {
  font-size: 80px;
  font-weight: bold;
}
.detail_sub2 {
  font-size: 1.375rem;
  margin-top: 1.25rem;
}
.detail_text2 {
  position: absolute;
  top: 10.3125rem;
  width: 100%;
  color: #ffffff;
  opacity: 0;
}
.detail_tab {
  margin-top: 7.375rem;
  width: 100%;
}
.dimension {
  display: inline-block;
  position: absolute;
  margin-left: -27.875rem;
  font-size: 1.875rem;
}
.div1 {
  display: inline-block;
  position: absolute;
  background-color: #ffffff;
  width: 13.5rem;
  height: 0.0625rem;
  margin-left: -14.375rem;
  margin-top: 1.275rem;
}
.div2 {
  width: 0.0625rem;
  height: 16rem;
  background-color: #ffffff;
  display: inline-block;
  margin-left: -0.9375rem;
  margin-top: 1.3125rem;
}

.detail_text3 {
  position: absolute;
  color: white;
  top: 7.5rem;
  right: 25rem;
  color: #000000;
  text-align: left;
  transform: translateX(-50%);
  opacity: 0;
}
.dimension3 {
  font-size: 1.375rem;
  margin-top: 1.25rem;
}
.detail_tab3 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 11.125rem;
}
.detail_tab3 div {
  flex: 0 0 50%;
  text-align: left;
  box-sizing: border-box;
  padding-left: 1.5625rem;
  font-size: 1.5rem;
  color: #333333ff;
  position: relative;
  margin-bottom: 2.5rem;
}
.detail_tab3 div::after {
  content: "";
  background: #333333ff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  width: 0.875rem;
  height: 0.875rem;
}
.detail2 {
  width: 100%;
  height: 81.5625rem;
  position: relative;
}
.detail_title2 {
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
}
.detail_sub3 {
  width: 58.75rem;
  font-size: 1.375rem;
  margin-top: 3.125rem;
}
.detail_text4 {
  position: absolute;
  top: 10.3125rem;
  width: 100%;
  color: #ffffff;
  opacity: 0;
}
.detail_orther {
  margin-top: 3.0625rem;
}
.detail_sub4 {
  font-size: 1.375rem;
  color: #000000ff;
  text-align: left;
}
.detail_text5 {
  position: absolute;
  left: 9.125rem;
  top: 25.4375rem;
  color: white;
}
.detail_text6 {
  position: absolute;
  left: 9.125rem;
  top: 11.875rem;
  color: #000000ff;
  font-size: 3.75rem;
  font-weight: bold;
}
/* 产品参数 */
.parameter_title {
  width: 100%;
  line-height: 13.75rem;
  height: 13.75rem;
  padding-left: 9.125rem;
  box-sizing: border-box;
  font-size: 3.75rem;
  text-align: left;
  color: #000000ff;
  font-weight: bold;
}
.parameter_tab {
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  padding-left: 9.125rem;
  box-sizing: border-box;
  background: #fafafa;
  text-align: left;
  font-size: 1.75rem;
  color: #000000ff;
}
.parameter_item {
  width: 101.75rem;
  margin: 0 auto;
  border-bottom: 0.0625rem solid #f5f5f5ff;
  display: flex;
  justify-content: flex-start;
  min-height: 4.5rem;
  align-items: center;
}
.parameter_item_title {
  width: 29.625rem;
  font-size: 1.25rem;
  color: #797979ff;
  text-align: left;
}
.parameter_item_content {
  max-width: 71.75rem;
  font-size: 1.25rem;
  color: #333333ff;
  text-align: left;
}
</style>
