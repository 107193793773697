<template>
  <div>
    <img src="@/assets/mobile/solution/img5.jpg" class="banner" />
    <span class="park_title">智慧公租房解决方案</span>
    <div class="content">
      <div class="title">方案概述</div>
      <div class="sub">
        智慧公租房解决方案是拥有丰富的功能与服务内容，方案由智慧公租房管理平台智慧管控终端、智慧租房应用端、云平台基础服务构成，从租房、签约、入住、安全管理，全方位服务租户与租客，提升客户粘性与服务质量。
      </div>
    </div>

    <img src="@/assets/mobile/solution/img6.jpg" class="blueprint" />
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.banner {
  width: 100%;
  height: 26rem;
}
.park_title {
  position: absolute;
  top: 12.125rem;
  left: 0;
  right: 0;
  font-size: 1.6875rem;
  color: #ffffff;
}
.title {
  font-size: 1.4375rem;
  color: #333333ff;
  text-align: left;
  font-weight: bold;
}
.content {
  width: 100%;
  padding: 2.625rem 1.9375rem;
  box-sizing: border-box;
}
.sub {
  width: 20rem;
  margin-top: 1.3125rem;
  font-size: 0.9375rem;
  text-align: left;
}

.blueprint {
  width: 100%;
  height: 32.875rem;
}
</style>