<template>
  <div>
    <div class="top">
      <img src="@/assets/mobile/product/banner.jpg" class="banner" />
      <div class="title">智能产品线</div>
      <div class="title">IOT物联网一触即连</div>
    </div>
    <van-tabs
      v-model="active"
      class="tablist"
      line-width="1.9375rem"
      line-height=".25rem"
      title-inactive-color="#000000"
      title-active-color="#60A6EC"
    >
      <van-tab
        :title="item.title"
        v-for="(item, index) in productTab"
        :key="index"
      >
        <div
          class="product_item"
          v-for="(citem, cindex) in item.chirden"
          :key="cindex"
        >
          <div class="product_name">{{ citem.name }}</div>
          <div class="product_tips">{{ citem.tips }}</div>
          <div class="product_more" @click="seeMore(citem.url)">
            <div>查看详情</div>
            <img src="@/assets/mobile/right.png" class="product_more_icon" />
          </div>
          <img :src="citem.img" class="product_img" />
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import img1 from "../../assets/mobile/product/1.png";
import img2 from "../../assets/mobile/product/2.png";
import img3 from "../../assets/mobile/product/3.png";
import img4 from "../../assets/mobile/product/4.png";
import img5 from "../../assets/mobile/product/5.png";
import img6 from "../../assets/mobile/product/6.png";
import img7 from "../../assets/mobile/product/7.png";
import img8 from "../../assets/mobile/product/8.png";
export default {
  data() {
    return {
      active: 0,
      productTab: [
        {
          title: "全部",
          chirden: [
            {
              name: "证码通RC13",
              tips: "一瞬核验",
              img: img1,
              url:'moblierc13'
            },
            {
              name: "证码通RC20",
              tips: "巡检小卫士",
              img: img2,
              url:'MoblieRc20'
            },
            {
              name: "智能电子学生证",
              tips: "移动小帮手",
              img: img3,
              url:'MoblieStudent'
            },
            {
              name: "证码通RC10",
              tips: "证码核验新方式",
              img: img4,
              url:'MoblieRc10'
            },
            {
              name: "智能发卡收银机AT21",
              tips: "无人售卡",
              img: img5,
               url:'moblieat21'
            },
            {
              name: "双屏收银机ST-B2",
              tips: "畅快收银",
              img: img6,
              url:'MoblieStb2'
            },
            {
              name: "实名制平板RC14",
              tips: "移动办公利器",
              img: img7,
              url:'MoblieRc14'
            },
            {
              name: "双屏⼈证核验终端AT31",
              tips: "安防小卫士",
              img: img8,
              url:'MoblieAt31'
            },
          ],
        },
        {
          title: "智能通行",
          chirden: [
            {
              name: "证码通RC13",
              tips: "一瞬核验",
              img: img1,
              url:'moblierc13'
            },
            {
              name: "证码通RC20",
              tips: "巡检小卫士",
              img: img2,
              url:'MoblieRc20'
            },
            {
              name: "双屏⼈证核验终端AT31",
              tips: "安防小卫士",
              img: img8,
              url:'MoblieAt31'
            },
          ],
        },
        {
          title: "自助服务",
          chirden: [
            {
              name: "智能发卡收银机AT21",
              tips: "无人售卡",
              img: img5,
              url:'moblieat21'
            },
            {
              name: "双屏收银机ST-B2",
              tips: "畅快收银",
              img: img6,
              url:'MoblieStb2'
            },
          ],
        },
        {
          title: "实名核验",
          chirden: [
            {
              name: "智能电子学生证",
              tips: "移动小帮手",
              img: img3,
              url:'MoblieStudent'
            },
            {
              name: "证码通RC10",
              tips: "证码核验新方式",
              img: img4,
              url:'MoblieRc10'
            },
            {
              name: "实名制平板RC14",
              tips: "移动办公利器",
              img: img7,
              url:'MoblieRc14'
            },
          ],
        },
        {
          title: "数据分析",
          chirden: [],
        },
      ],
    };
  },

  created() {
    var index = this.$route.query.index;

    if (index) {
      this.$nextTick(() => {
        this.active = Number(index);
      });
    }
  },

  methods: {
    // 查看详情
    seeMore(url) {
      this.$router.replace({
        name: url,
      });
    },
  },
};
</script>

<style scoped>
.top {
  width: 100%;
  height: 26rem;
  padding-top: 7.6875rem;
  box-sizing: border-box;
  position: relative;
}
.banner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.title {
  font-size: 1.6875rem;
  color: white;
  font-weight: bold;
}
.tablist {
  margin-top: 2.0625rem;
}
::v-deep .van-tabs__nav {
  width: 100%;
  padding: 0 1rem 1.5625rem 1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
::v-deep .van-tab__text--ellipsis {
  font-size: .9375rem;
  font-weight: bold;
}
::v-deep .van-tabs__line {
  background: #60a6ec;
}
::v-deep .van-tab {
  flex: none;
}
.product_item {
  width: 22.4375rem;
  height: 26rem;
  padding-top: 1.9375rem;
  box-sizing: border-box;
  margin: 0 auto 1rem;
  background: #f4f4f4;
}
.product_name {
  font-size: 1.5625rem;
  color: #000;
  font-weight: bold;
}
.product_tips {
  margin: .125rem 0 .875rem;
  font-size: 1.0625rem;
  color: #333333;
}
.product_more {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .9375rem;
  color: #448ef7;
}
.product_more_icon {
  width: .375rem;
  height: .6875rem;
  margin-left: .375rem;
}
.product_img {
  width: 100%;
  height: 15.625rem;
  margin-top: 1.3125rem;
}
</style>