<template>
  <div>
    <div class="detail">
      <img src="@/assets/product/stb2/img1.png" class="detail_img" />
      <div :class="['detail_text', 'gradient_right']">
        <div class="detail_title">ST-B2双屏收银机</div>
        <div class="detail_sub">双屏收银，简单易用</div>
      </div>
      <div :class="['detail_tab', show1 ? 'gradient_right' : '']">
        <div class="detail_single" v-for="item in category" :key="item.name">
          <img :src="item.img" class="detail_single_icon" />
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>

    <div class="detail">
      <img
        src="@/assets/product/stb2/img10.png"
        class="detail_img"
        ref="targetElement"
      />
      <div :class="['detail_text2', show2 ? 'gradient_left' : '']">
        <div class="detail_title2">双屏异显 场景交互</div>
        <div class="detail_sub2">
          <span style="font-weight: bold">主屏：</span>多点触摸，操作流畅
        </div>
        <div class="detail_sub2" style="margin-top: 2.5rem">
          <span style="font-weight: bold">副屏：</span
          >15.6寸高清工业级LCD屏还原真彩显示
        </div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/stb2/img11.png"
        class="detail_img"
        ref="targetElement2"
      />
      <div :class="['detail_text3', show3 ? 'gradient_top' : '']">
        <div class="detail_title3">桌面式</div>
        <div class="detail_sub3">
          精巧机身设计占用空间小
          <div>主副屏幕角度可调最大30度，方便操作</div>
        </div>
      </div>
    </div>

    <div class="parameter">
      <div class="parameter_title">产品参数</div>
      <div v-for="(item, index) in argumentsList" :key="index">
        <div class="parameter_tab">{{ item.name }}</div>
        <div
          class="parameter_item"
          v-for="citem in item.list"
          :key="citem.name"
        >
          <div class="parameter_item_title">{{ citem.name }}</div>
          <div class="parameter_item_content">{{ citem.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";

import icon1 from "../../assets/product/stb2/img2.png";
import icon2 from "../../assets/product/stb2/img3.png";
import icon3 from "../../assets/product/stb2/img4.png";
import icon4 from "../../assets/product/stb2/img5.png";
import icon5 from "../../assets/product/stb2/img6.png";
import icon6 from "../../assets/product/stb2/img7.png";
import icon7 from "../../assets/product/stb2/img8.png";
import icon8 from "../../assets/product/stb2/img9.png";
export default {
  data() {
    return {
      argumentsList: [
        {
          name: "常规参数",
          list: [
            {
              name: "产品名称与型号",
              content: "双屏收银机ST-B2",
            },
            {
              name: "系统",
              content: "Android 7.1 SrTi安全操作系统",
            },
            {
              name: "CPU",
              content: "ARM Cortex-A17 四核 1.8GHz",
            },
            {
              name: "扩展存储",
              content: "支持MicroSD (TF) 卡扩展（最高64G）",
            },
            {
              name: " 内存",
              content: "RAM2GB + ROM8GB （可选配2+16GB）",
            },
            {
              name: "以太网",
              content: "RJ45接口，10/100M",
            },
            {
              name: "Wi-Fi",
              content: "WiFi 2.4GHz，支持IEEE 802.11 b/g/n",
            },
            {
              name: " USB",
              content: "底部USB*4，OTG*1 ，侧面USB*2",
            },
            {
              name: "扫码器(选配)",
              content:
                "内置扫码器，可识别各类主流一维条码及标准二维条码（QR Code, Code 128, EAN-13, EAN-8,Matrix等），支持USB扩展",
            },
            {
              name: "打印机(选配）",
              content: "58热敏打印机",
            },
            {
              name: " 电源",
              content: " 输入：AC 100~240V/ 2A ，输出：DC 24V/2.71A",
            },
            {
              name: "其他接口",
              content: "钱箱接口：RJ11*1，电子秤接口：DB9*1",
            },
            {
              name: "整机尺寸",
              content: "400mm（长）*400mm（宽）*370mm（高）",
            },
            {
              name: "整机重量",
              content: "7.5Kg（裸机，不含包装箱）",
            },
            {
              name: "工作环境",
              content: "工作温度：0°C～40 °C ，存储温度： - 20°C～60°C",
            },
          ],
        },
      ],
      category: [
        {
          name: "扫码支付",
          img: icon1,
        },
        {
          name: "扫商品码",
          img: icon2,
        },
        {
          name: "凭条打印",
          img: icon3,
        },
        {
          name: "语音提示",
          img: icon4,
        },
        {
          name: "扩展存储",
          img: icon5,
        },
        {
          name: "双屏异显",
          img: icon6,
        },
        {
          name: "电子秤",
          img: icon7,
        },
        {
          name: "钱箱",
          img: icon8,
        },
      ],
      show1: false, //是否显示
      show2: false, //是否显示
      show3: false, //是否显示
    };
  },

  created() {
    setTimeout(() => {
      this.show1 = true;
    }, 800);
  },
  directives: {
    ScrollTo: VueScrollTo.directive,
  },
  mounted() {
       this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    windowNaNpxoveEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 获取页面滚动距离
    handleScroll() {
      const element = this.$refs.targetElement;
      const element2 = this.$refs.targetElement2;
      const rect = element.getBoundingClientRect();
      const rect2 = element2.getBoundingClientRect();

      if (rect.top <= window.innerHeight && rect.bottom <= 1500) {
        if (!this.show2) {
          this.show2 = true;
        }
      }

      if (rect2.top <= window.innerHeight && rect2.bottom <= 1500) {
        if (!this.show3) {
          this.show3 = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.detail {
  width: 100%;
  height: 67.5rem;
  position: relative;
}
.detail_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.detail_text {
  color: white;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 14.9375rem;
  left: auto;
  right: 16.3125rem;
  opacity: 0;
}

.detail_title {
  font-size: 5rem;
  font-weight: bold;
  text-align: left;
}
.detail_sub {
  font-size: 2rem;
  text-align: left;
  margin-top: 3.75rem;
}
.detail_bottom {
  position: absolute;
  right: 27.5rem;
  bottom: 14.5625rem;
  display: flex;
  width: 25.375rem;
  flex-wrap: wrap;
  justify-content: space-between;
}
.detail_bottom_item {
  width: 18.75rem;
  height: 5.25rem;
  display: flex;
}
.detail_img_item {
  width: 2.75rem;
  height: 2.75rem;
}
.detail_bottom_text {
  height: 2.75rem;
  line-height: 2.75rem;
  margin-left: 0.625rem;
  font-size: 1.5rem;
}
.detail_content2 {
  padding-top: 12.125rem;
  padding-left: 9.125rem;
  padding-right: 9.125rem;
  display: flex;
  justify-content: space-between;
}
.detail_item2 {
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.detail_img_item2 {
  width: 27.375rem;
  height: 23.75rem;
}
.detail_item_text2 {
  font-size: 3rem;
  margin-top: 5.5rem;
  margin-bottom: 3.375rem;

  font-weight: bold;
}
.detail_bottom_text2 {
  font-size: 1.375rem;
  width: 17.5rem;
}
.detail_title2 {
  font-size: 5rem;
  font-weight: bold;
  text-align: left;
  color: #000000;
  margin-bottom: 1.25rem;
}
.detail_sub2 {
  font-size: 1.375rem;
  text-align: left;
  color: #333333;
}
.detail_text2 {
  position: absolute;
  top: 26.8125rem;
  left: 9.125rem;
}

.detail_text3 {
  position: absolute;
  color: #000000;
  width: 100%;
  text-align: center;
  opacity: 0;
}
.detail_title3 {
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 7.5rem;
}
.detail_sub3 {
  font-size: 1.375rem;
  margin-top: 1.25rem;
}

.detail_text5 {
  text-align: left;
  width: 100%;
}
.detail_title5 {
  font-size: 3.75rem;
  font-weight: bold;
  color: #000000;
  height: 13.75rem;
  margin-left: 9.125rem;
  line-height: 13.75rem;
}
.table-second {
  width: 100%;
  height: 5rem;
  background: #fafafa;
  padding-left: 9.125rem;
  font-size: 1.75rem;
  line-height: 5rem;
  color: #000000;
}
.table-third {
  width: auto;
  margin-left: 9.125rem;
  margin-right: 9.125rem;
  display: flex;
}
.table-item {
  min-height: 4.5rem;
  line-height: 4.5rem;
  color: #797979;
  font-size: 1.25rem;
  border-bottom: 0.0625rem solid #f5f5f5;
}
.table_left {
  width: 30%;
}
.table-right {
  width: 70%;
}
.table_left_item {
  height: 4.5rem;
  line-height: 4.5rem;
  border-bottom: 0.0625rem solid #f5f5f5;
}
.table_left_item2 {
  height: 6.625rem !important;
  line-height: 6.625rem;
}
.table_right_item {
  height: 6.625rem;
  border-bottom: 0.0625rem solid #f5f5f5;
  vertical-align: middle;
  display: table-cell;
}
/* 产品参数 */
.parameter_title {
  width: 100%;
  line-height: 13.75rem;
  height: 13.75rem;
  padding-left: 9.125rem;
  box-sizing: border-box;
  font-size: 3.75rem;
  text-align: left;
  color: #000000ff;
  font-weight: bold;
}
.parameter_tab {
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  padding-left: 8.75rem;
  box-sizing: border-box;
  background: #fafafa;
  text-align: left;
  font-size: 1.75rem;
  color: #000000ff;
}
.parameter_item {
  width: 101.75rem;
  margin: 0 auto;
  border-bottom: 0.0625rem solid #f5f5f5ff;
  display: flex;
  justify-content: flex-start;
  min-height: 4.5rem;
  align-items: center;
}
.parameter_item_title {
  width: 29.625rem;
  font-size: 1.25rem;
  color: #797979ff;
  text-align: left;
}
.parameter_item_content {
  max-width: 71.75rem;
  font-size: 1.25rem;
  color: #333333ff;
  text-align: left;
}
.detail_tab {
  position: absolute;
  opacity: 0;
  bottom: 9.75rem;
  right:22.8125rem;
  width: 31.25rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.detail_single {
  display: flex;
  align-items: center;
  flex: 0 0 48%;
  font-size: 1.5rem;
  color: white;
  margin-bottom: 2.5rem;
}
.detail_single_icon {
  width: 2.75rem;
  height: 2.75rem;
  margin-right: 1rem;
}
</style>
