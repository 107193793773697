<template>
  <div>
    <div class="detail_item">
      <img src="@/assets/mobile/product/rc10/1.jpg" class="detail_img" />
      <div :class="['detail_text', 'gradient_top']">
        <div class="detail_title">证码通RC10</div>
        <div class="detail_title">实名制Mini核验终端</div>
        <div class="detail_tips">内置安全芯片满足国密标准</div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement">
      <img src="@/assets/mobile/product/rc10/2.jpg" class="detail_img" />
      <div :class="['detail_text2', show1 ? 'gradient_left' : '']">
        <div class="detail_title2">多证可读</div>
        <div class="detail_tips2">
          支持读取ISO/IEC14443非接触卡TypeA卡、TypeB卡
        </div>
      </div>
      <div :class="['detail_ul', show1 ? 'gradient_left' : '']">
        <div class="detail_li">身份证</div>
        <div class="detail_li">港澳台居民居住证</div>
        <div class="detail_li">外国人永久居留证</div>
        <div class="detail_li">eID电子证件</div>
        <div class="detail_li">护照</div>
        <div class="detail_li">老年卡</div>
        <div class="detail_li">市政卡</div>
        <div class="detail_li">公交卡</div>
        <div class="detail_li">学生卡</div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement2">
      <img src="@/assets/mobile/product/rc10/3.jpg" class="detail_img" />
      <div :class="['detail_text3', show2 ? 'gradient_left' : '']">
        <div class="detail_title3">便携可移动</div>
        <div class="detail_tips3">4G网络、内置电池超长待机、体积小巧</div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement3">
      <img src="@/assets/mobile/product/rc10/4.jpg" class="detail_img" />
      <div :class="['detail_text4', show3 ? 'gradient_top' : '']">
        <div class="detail_title4">开放接口,支持应用扩展</div>
        <div class="detail_tips4">可接入电子政务、运营商等第三方系统</div>
      </div>
    </div>
    <!-- 产品参数 -->
    <div class="parameter">
      <div class="parameter_title">产品参数</div>
      <div v-for="(item, index) in argumentsList" :key="index">
        <div class="parameter_tab">{{ item.name }}</div>
        <div
          class="parameter_item"
          v-for="citem in item.list"
          :key="citem.name"
        >
          <div class="parameter_item_name">{{ citem.name }}</div>
          <div class="parameter_item_anw">{{ citem.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
export default {
  data() {
    return {
      argumentsList: [
        {
          name: "规格参数",
          list: [
            {
              name: "产品名称与型号",
              content: "证码通RC10",
            },
            {
              name: "系统",
              content: "ARM Cortex-R5处理器",
            },
            {
              name: "内存",
              content: "16MBRAM+16MBROM",
            },
            {
              name: "网络",
              content: "支持移动、联通、电信4G/5G网络通讯",
            },
            {
              name: "屏幕",
              content: "2.4寸LCD液晶屏,分辨率240*320",
            },
            {
              name: "读卡",
              content: "兼容大小卡(SIM/USIM大/小卡)",
            },
            {
              name: "电池",
              content: "3.7V可充电锂电池,容量2000mAh",
            },
            {
              name: "喇叭",
              content: "1W",
            },
            {
              name: "接口",
              content: "Type-C兼容数据与充电口",
            },
            {
              name: "整机尺寸",
              content: "126mm(长)*74.6mm(宽)*12mm(厚)",
            },
            {
              name: "工作环境",
              content: "工作温度:0°C~50°C\n存储温度:-40°C~70°C",
            },
          ],
        },
      ],
      show1: false,
      show2: false,
      show3: false,
    };
  },
  directives: {
    ScrollTo: VueScrollTo.directive,
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    windowNaNpxoveEventListener("scroll", this.handleScroll2);
  },

  methods: {
    // 获取页面滚动距离
    handleScroll() {
      const element = this.$refs.targetElement;
      const element2 = this.$refs.targetElement2;
      const element3 = this.$refs.targetElement3;

      if (element) {
        const rect = element.getBoundingClientRect();
        const rect2 = element2.getBoundingClientRect();
        const rect3 = element3.getBoundingClientRect();

        if (rect.top <= window.innerHeight && rect.bottom <= 1200) {
          // 元素在可视区域内
          if (!this.show1) {
            this.show1 = true;
          }
        }
        if (rect2.top <= window.innerHeight && rect2.bottom <= 1200) {
          if (!this.show2) {
            this.show2 = true;
          }
        }
        if (rect3.top <= window.innerHeight && rect3.bottom <= 1200) {
          if (!this.show3) {
            this.show3 = true;
          }
        }
      }
    },
  },
};
</script>

<style  scoped>
.detail_item {
  width: 100%;
  height: 36.6875rem;
  position: relative;
}
.detail_item2 {
  width: 100%;
  height: 33.125rem;
  position: relative;
}
.detail_img {
  width: 100%;
  height: 100%;
}
.detail_text {
  position: absolute;
  top: 6.125rem;
  left: 0;
  opacity: 0;
  width: 100%;
}
.detail_title {
  font-size: 1.6875rem;
  color: #000;
  font-weight: bold;
}
.detail_tips {
  margin-top: 1.1875rem;
  font-size: 0.9375rem;
  color: #000;
}
.detail_text2 {
  opacity: 0;
  position: absolute;
  left: 1.9375rem;
  top: 2.6875rem;
}
.detail_title2 {
  font-size: 1.6875rem;
  color: #000;
  font-weight: bold;
  text-align: left;
}
.detail_tips2 {
  font-size: 0.9375rem;
  color: #000;
  width: 19.5rem;
  margin-top: 1.1875rem;
  text-align: left;
}
.detail_ul {
  position: absolute;
  opacity: 0;
  bottom: 3.625rem;
  left: 2.75rem;
}
.detail_li {
  font-size: 0.875rem;
  color: #333333;
  margin-top: 0.9375rem;
  text-align: left;
  position: relative;
}
.detail_li::after {
  content: "";
  left: -0.9375rem;
  top: 50%;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  position: absolute;
  background: #333333;
  transform: translateY(-50%);
}
.detail_text3 {
  top: 3.875rem;
  opacity: 0;
  left: 1.9375rem;
  position: absolute;
}
.detail_title3 {
  font-size: 1.6875rem;
  color: #000;
  font-weight: bold;
  text-align: left;
}
.detail_tips3 {
  font-size: 0.9375rem;
  color: #000;
  text-align: left;
  margin-top: 1.1875rem;
}
.detail_text4 {
  position: absolute;
  opacity: 0;
  top: 3.875rem;
  left: 0;
  width: 100%;
}
.detail_title4 {
  font-size: 1.6875rem;
  color: white;
  font-weight: bold;
}
.detail_tips4 {
  font-size: 0.9375rem;
  color: white;
  margin-top: 1.1875rem;
}
.parameter {
  margin-top: 2.625rem;
}
.parameter_title {
  font-size: 1.4375rem;
  color: #000;
  font-weight: bold;
  padding-left: 1.3125rem;
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 1.3125rem;
}
.parameter_tab {
  width: 100%;
  height: 3.5625rem;
  background: #fafafa;
  text-align: left;
  font-size: 1.0625rem;
  padding-left: 1.3125rem;
  font-weight: bold;
  color: #000;
  box-sizing: border-box;
  line-height: 3.5625rem;
}
.parameter_item {
  width: 100%;
  padding: 1.3125rem 0;
  display: flex;
  align-items: center;
  border-bottom: 0.0625rem solid #dddddd;
}
.parameter_item_name {
  width: 4.5625rem;
  height: 100%;
  margin-right: 2.625rem;
  margin-left: 1.3125rem;
  box-sizing: border-box;
  text-align: left;
  font-size: 0.9375rem;
  color: #797979;
}
.parameter_item_anw {
  width: 13.75rem;
  word-wrap: break-word;
  white-space: pre-line;
  font-size: 0.9375rem;
  text-align: left;
  color: #333333;
}
</style>