<template>
  <div>
    <div class="detail_item">
      <img src="@/assets/mobile/product/rc14/1.jpg" class="detail_img" />
      <div :class="['detail_text', 'gradient_top']">
        <div class="detail_title">实名制平板RC14</div>
        <div class="detail_tips">多屏互动,一触即达</div>
      </div>
      <div :class="['detail_tab', show1 ? 'gradient_top' : '']">
        <div class="detail_tab_li" v-for="(item, index) in list" :key="index">
          <img :src="item.img" class="detail_tab_icon" />
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="detail_item3" ref="targetElement">
      <img src="@/assets/mobile/product/rc14/2.jpg" class="detail_img" />
      <div :class="['detail_text2_tab', show2 ? 'gradient_left' : '']">
        <img
          src="@/assets/mobile/product/rc14/img1.png"
          class="detail_text2_img"
        />
        <div class="detail_text2_right2">
          <div class="detail_title2">高清人摄</div>
          <div class="detail_tips2">1080P翻转高清人摄</div>
          <div class="detail_tips2">精准人像采集</div>
        </div>
      </div>
      <div :class="['detail_text2_tab', show3 ? 'gradient_right' : '']">
        <div class="detail_text2_right">
          <div class="detail_title2">文稿扫描</div>
          <div class="detail_tips2">超清扫描摄像头</div>
          <div class="detail_tips2">纸质文稿电子化显示</div>
        </div>
        <img
          src="@/assets/mobile/product/rc14/img2.png"
          class="detail_text2_img"
        />
      </div>
      <div :class="['detail_text2_tab', show4 ? 'gradient_left' : '']">
        <img
          src="@/assets/mobile/product/rc14/img3.png"
          class="detail_text2_img"
        />
        <div class="detail_text2_right2">
          <div class="detail_title2">灵活影柱</div>
          <div class="detail_tips2">影柱可折叠</div>
          <div class="detail_tips2">拔插主机便携随心用</div>
        </div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement2">
      <img src="@/assets/mobile/product/rc14/3.jpg" class="detail_img" />
      <div :class="['detail_text3', show5 ? 'gradient_left' : '']">
        <div class="detail_title3">超清大屏</div>
        <div class="detail_ul">
          <div class="detail_li" v-for="(item, index) in li" :key="index">
            <div class="yuan"></div>
            <div class="detail_tips3">
              {{ item.name }}<span>{{ item.tips }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement3">
      <img src="@/assets/mobile/product/rc14/4.jpg" class="detail_img" />
      <div :class="['detail_text4', show6 ? 'gradient_top' : '']">
        <div class="detail_title4">多屏互动 一触即达</div>
        <div class="detail_tips4">支持无线投屏,有线HDMI接口投屏</div>
        <div class="detail_tips4">音频跟随投屏设备播放</div>
        <div class="detail_tips4">打破边界创造更多协作空间</div>
      </div>
      <div :class="['detail_ul2', show7 ? 'gradient_bottom' : '']">
        <div class="detail_li2">
          <img
            src="@/assets/mobile/product/rc14/img4.png"
            class="detail_detail_li2_img"
          />
          <div>投屏电脑</div>
        </div>
        <div class="detail_li2">
          <img
            src="@/assets/mobile/product/rc14/img5.png"
            class="detail_detail_li2_img"
          />
          <div>投屏大屏</div>
        </div>
        <div class="detail_li2">
          <img
            src="@/assets/mobile/product/rc14/img6.png"
            class="detail_detail_li2_img"
          />
          <div>投屏投影仪</div>
        </div>
      </div>
    </div>

    <!-- 产品参数 -->
    <div class="parameter">
      <div class="parameter_title">产品参数</div>
      <div v-for="(item, index) in parameter" :key="index">
        <div class="parameter_tab">{{ item.title }}</div>
        <div
          class="parameter_item"
          v-for="citem in item.chirdren"
          :key="citem.name"
        >
          <div class="parameter_item_name">{{ citem.name }}</div>
          <div class="parameter_item_anw">{{ citem.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
import icon1 from "../../assets/mobile/product/rc14/icon1.png";
import icon2 from "../../assets/mobile/product/rc14/icon1.png";
import icon3 from "../../assets/mobile/product/rc14/icon1.png";
import icon4 from "../../assets/mobile/product/rc14/icon1.png";
import icon5 from "../../assets/mobile/product/rc14/icon1.png";
import icon6 from "../../assets/mobile/product/rc14/icon1.png";
export default {
  data() {
    return {
      list: [
        {
          img: icon1,
          name: "读身份证",
        },
        {
          img: icon2,
          name: "文稿扫描",
        },
        {
          img: icon3,
          name: "扩展投屏",
        },
        {
          img: icon4,
          name: "人脸识别",
        },
        {
          img: icon5,
          name: "灵活笔写",
        },
        {
          img: icon6,
          name: "开放下载",
        },
      ],
      li: [
        {
          name: "精细画质:",
          tips: "高清分辨率1920*1200",
        },
        {
          name: "多点触屏:",
          tips: "手写、笔写行云流水",
        },
        {
          name: "真彩显示:",
          tips: "色彩生动鲜明",
        },
        {
          name: "10.1英寸:",
          tips: "舒适观享体验",
        },
      ],
      parameter: [
        {
          title: "规格参数",
          chirdren: [
            {
              name: "产品名称与型号",
              content: "实名制终端RC14",
            },
            {
              name: "系统",
              content: "Android 7.1及以上",
            },
            {
              name: "CPU",
              content: "RK3288四核Cortex-A17,主频最高达1.8GHz",
            },
            {
              name: "存储",
              content: "2+8G、2+16G(选配)、4+32G(选配)",
            },
            {
              name: "网络",
              content:
                "以太网百兆,WIFI2.4G,支持IEEE802.11b/g/n;WIFI5GIEEE802.11a/b/g/n(选配),4G三网通支持移动、联通、电信(选配)",
            },
            {
              name: "蓝牙",
              content: "蓝牙4.0(HS)支持BLE(选配)",
            },
            {
              name: "屏幕",
              content: "IPS10.1⼨屏,分辨率1920x1080,亮度可调节",
            },
            {
              name: "触摸",
              content: "多点触摸电容屏G+G,支持电容笔书写",
            },
            {
              name: "人像摄像头(平板)",
              content:
                "像素500W,焦距3.34±5%,场视角60°,视频分辨率1080P,30帧",
            },
            {
              name: "扫描摄像头(高拍)",
              content:
                "像素500W,焦距3.4±5%,场视角60°,视频分辨率1080P,30帧,带补光灯",
            },
            {
              name: "最大扫描范围",
              content: "A4纸 (210mm*297mm)",
            },
            {
              name: "射频技术",
              content: "NFC13.56MHz",
            },
            {
              name: "读证模块",
              content:
                "支持读取ISO/IEC14443 非接触卡TypeA卡/TypeB卡,包含身份证、港澳台居民居住证、外国人永久居留证、公交卡、市政卡、老年卡、学生卡、护照、elD电子证件等",
            },
            {
              name: "接口",
              content:
                "USB TYPE-A *2、USB TYPE-C*1、DC供电接口*1、RJ45网口*1、HDMI*1、TF卡槽*1",
            },
            {
              name: "整机尺寸",
              content:
                "平板尺寸\n265mm*210mm*44.85mm\n影柱\n53.8mm*35.8mm*301.5mm",
            },
            {
              name: "工作环境",
              content: "工作温度:0°C~50 °C\n存储温度: - 20°C~60°C",
            },
          ],
        },
      ],
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
    };
  },

  directives: {
    ScrollTo: VueScrollTo.directive,
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    windowNaNpxoveEventListener("scroll", this.handleScroll);
  },

  created() {
    setTimeout(() => {
      this.show1 = true;
    }, 1000);
  },

  methods: {
    // 获取页面滚动距离
    handleScroll() {
      const element = this.$refs.targetElement;
      const element2 = this.$refs.targetElement2;
      const element3 = this.$refs.targetElement3;

      const rect = element.getBoundingClientRect();
      const rect2 = element2.getBoundingClientRect();
      const rect3 = element3.getBoundingClientRect();

      if (rect.top <= window.innerHeight && rect.bottom <= 1200) {
        // 元素在可视区域内
        if (!this.show2) {
          this.show2 = true;
          setTimeout(() => {
            if (!this.show3) {
              this.show3 = true;
              setTimeout(() => {
                if (!this.show4) {
                  this.show4 = true;
                }
              }, 800);
            }
          }, 800);
        }
      }

      if (rect2.top <= window.innerHeight && rect2.bottom <= 1200) {
        if (!this.show5) {
          this.show5 = true;
        }
      }
      if (rect3.top <= window.innerHeight && rect3.bottom <= 1200) {
        if (!this.show6) {
          this.show6 = true;
          setTimeout(() => {
            if (!this.show7) {
              this.show7 = true;
            }
          }, 1000);
        }
      }
      //   if (rect4.top <= window.innerHeight && rect4.bottom <= 1500) {
      //     if (!this.show4) {
      //       this.show4 = true;
      //     }
      //   }
      //   if (rect5.top <= window.innerHeight && rect5.bottom <= 1500) {
      //     if (!this.show5) {
      //       this.show5 = true;
      //     }
      //   }
      //   if (rect6.top <= window.innerHeight && rect6.bottom <= 1500) {
      //     if (!this.show6) {
      //       this.show6 = true;
      //     }
      //   }
      //   if (rect7.top <= window.innerHeight && rect7.bottom <= 1500) {
      //     if (!this.show7) {
      //       this.show7 = true;
      //     }
      //   }
    },
  },
};
</script>

<style  scoped>
.detail_item {
  width: 100%;
  height: 36.6875rem;
  position: relative;
}
.detail_item2 {
  width: 100%;
  height: 33.125rem;
  position: relative;
}
.detail_item3 {
  width: 100%;
  height: 33.125rem;
  padding: 1.9375rem 2.75rem 0 1.9375rem;
  box-sizing: border-box;
  position: relative;
}
.detail_img {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
}
.detail_text {
  top: 6.125rem;
  left: 0;
  opacity: 0;
  width: 100%;
  position: absolute;
}
.detail_title {
  font-size: 1.6875rem;
  font-weight: bold;
  color: white;
  margin-bottom: 0.625rem;
}
.detail_tips {
  font-size: 0.9375rem;
  color: white;
}
.detail_tab {
  position: absolute;
  top: 12.0625rem;
  left: 1.9375rem;
  width: 19.4375rem;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.detail_tab_li {
  display: flex;
  align-items: center;
  color: white;
  font-size: 0.75rem;
  margin-bottom: 1.5625rem;
}
.detail_tab_icon {
  width: 1.5625rem;
  height: 1.5625rem;
  margin-right: 0.375rem;
}
.detail_text2_tab {
  display: flex;
  opacity: 0;
  margin-bottom: 1.9375rem;
  box-sizing: border-box;
  align-items: flex-start;
}
.detail_text2_img {
  width: 50%;
  height: 8.4375rem;
}
.detail_text2_right {
  flex: 1;
}
.detail_text2_right2 {
  padding-left: 0.75rem;
  box-sizing: border-box;
  flex: 1;
}
.detail_title2 {
  font-size: 1.4375rem;
  color: #000;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.625rem;
}
.detail_tips2 {
  font-size: 0.9375rem;
  text-align: left;
  color: #000000;
}
.detail_text3 {
  opacity: 0;
  position: absolute;
  top: 3.875rem;
  left: 2.625rem;
}
.detail_title3 {
  font-size: 1.6875rem;
  color: #000;
  font-weight: bold;
  text-align: left;
}
.detail_ul {
  margin-top: 2.1875rem;
}
.detail_li {
  display: flex;
  margin-bottom: 0.9375rem;
  align-items: center;
}
.yuan {
  width: 0.5rem;
  height: 0.5rem;
  background: #000;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.detail_tips3 {
  font-size: 0.9375rem;
  color: #000;
  font-weight: bold;
}
.detail_tips3 span {
  font-weight: normal;
}
.detail_text4 {
  position: absolute;
  top: 3.875rem;
  opacity: 0;
  left: 0;
  width: 100%;
}
.detail_title4 {
  font-size: 1.6875rem;
  color: white;
  font-weight: bold;
  margin-bottom: 0.9375rem;
}
.detail_tips4 {
  font-size: 0.9375rem;
  color: white;
}
.detail_ul2 {
  position: absolute;
  bottom: 2.0625rem;
  left: 1.9375rem;
  opacity: 0;
  display: flex;
  align-items: center;
}
.detail_li2 {
  margin-right: 1.9375rem;
  color: white;
  font-weight: bold;
  font-size: 0.9375rem;
}
.detail_detail_li2_img {
  width: 5.4375rem;
  height: 5.4375rem;
  margin-bottom: 0.625rem;
}
.parameter {
  margin-top: 2.625rem;
}
.parameter_title {
  font-size: 1.4375rem;
  color: #000;
  font-weight: bold;
  padding-left: 1.3125rem;
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 1.3125rem;
}
.parameter_tab {
  width: 100%;
  height: 3.5625rem;
  background: #fafafa;
  text-align: left;
  font-size: 1.0625rem;
  padding-left: 1.3125rem;
  font-weight: bold;
  color: #000;
  box-sizing: border-box;
  line-height: 3.5625rem;
}
.parameter_item {
  width: 100%;
  padding: 1.3125rem 0;
  display: flex;
  align-items: center;
  border-bottom: 0.0625rem solid #dddddd;
}
.parameter_item_name {
  width: 4.5625rem;
  height: 100%;
  margin-right: 2.625rem;
  margin-left: 1.3125rem;
  box-sizing: border-box;
  text-align: left;
  font-size: 0.9375rem;
  color: #797979;
}
.parameter_item_anw {
  width: 13.75rem;
  word-wrap: break-word;
  white-space: pre-line;
  font-size: 0.9375rem;
  text-align: left;
  color: #333333;
}
</style>