<template>
  <div>
    <img src="@/assets/product/banner.png" class="banner" />
    <div class="tablist">
      <div
        v-for="item in tabList"
        :key="item.index"
        :class="['tab', selectIndex == item.index ? 'selecttab' : '']"
        @click="switchTab(item.index)"
      >
        {{ item.name }}
      </div>
    </div>

    <div class="product">
      <div v-for="item in copy" :key="item.name" class="item">
        <div class="product_item">
          <div class="title">{{ item.name }}</div>
          <div class="sub">{{ item.sub }}</div>
          <div class="more" @click="skip(item.url)">
            <div>查看详情</div>
            <img src="@/assets/productright.png" class="more_icon" />
          </div>
          <img :src="item.img" class="pimg" v-if="item.img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i1 from "../assets/product/index/i1.png";
import i2 from "../assets/product/index/i2.png";
import i3 from "../assets/product/index/i3.png";
import i4 from "../assets/product/index/i4.png";
import i5 from "../assets/product/index/i5.png";
import i6 from "../assets/product/index/i6.png";
import i7 from "../assets/product/index/i7.png";
import i8 from "../assets/product/index/i8.png";
import i9 from "../assets/product/index/i9.png";
import i10 from "../assets/product/index/i10.png";
import i11 from "../assets/product/index/i11.png";
export default {
  data() {
    return {
      selectIndex: 0, //当前tab索引
      tabList: [
        {
          name: "全部",
          index: 0,
        },
        {
          name: "智能通行",
          index: 1,
        },
        {
          name: "自助服务",
          index: 2,
        },
        {
          name: "实名核验",
          index: 3,
        },
        {
          name: "数据分析",
          index: 4,
        },
      ],

      productList: [
        {
          name: "证码通RC13",
          sub: "一瞬核验",
          img: i1,
          url: "/rc13",
          belong: 1,
        },
        {
          name: "证码通RC20",
          sub: "巡检小卫士",
          img: i2,
          url: "/pda",
          belong: 1,
        },
        {
          name: "智能电子学生证",
          sub: "移动小帮手",
          img: i3,
          url: "/studentCard",
          belong: 3,
        },
        {
          name: "证码通RC10",
          sub: "证码核验新方式",
          img: i4,
          url: "/rc10",
          belong: 3,
        },
        {
          name: "智能发卡收银机AT21",
          sub: "无人售卡",
          img: i5,
          url: "/at21",
          belong: 2,
        },
        {
          name: "双屏收银机ST-B2",
          sub: "畅快收银",
          img: i6,
          url: "/stb2",
          belong: 2,
        },
        {
          name: "实名制平板RC14",
          sub: "移动办公利器",
          img: i7,
          url: "/rc14",
          belong: 3,
        },
        {
          name: "双屏人证核验终端AT31",
          sub: "安防小卫士",
          img: i8,
          url: "/at31",
          belong: 1,
        },
        // {
        //   name: "NPU边缘服务器",
        //   sub: "神经网络计算",
        //   img: i9,
        //   belong: 4,
        // },
        // {
        //   name: "CPU边缘服务器",
        //   sub: "中央计算",
        //   img: i10,
        //   belong: 4,
        // },
        // {
        //   name: "通道闸机K1",
        //   sub: "智能出入口管理",
        //   img: i11,
        //   belong: 1,
        // },
        // {
        //   name: "人证比对机",
        //   sub: "多功能高效核验",
        //   belong: 3,
        // },
        // {
        //   name: "智能门锁",
        //   sub: "AI一级防护",
        //   belong: 1,
        // },
        // {
        //   name: "GPU局域网服务器",
        //   sub: "图形计算",
        //   belong: 4,
        // },
      ],
      copy: [], //处理过的数组
    };
  },

  created() {
    this.copy = this.productList;

    var index = this.$route.query.index;
    if (index) {
      this.switchTab(index);
    }
  },

  watch: {
    $route: {
      handler() {
        var index = this.$route.query.index;
        if (index) {
          this.switchTab(index);
        }
        //深度监听，同时也可监听到param参数变化
      },
      deep: true,
    },
  },

  methods: {
    // 切换tab
    switchTab(index) {
      this.selectIndex = index;
      var arr = this.productList.filter((item) => item.belong == index);
      if (index != 0) {
        this.copy = arr;
      } else {
        this.copy = this.productList;
      }
    },

    // 跳转详情
    skip(path) {
      if (!path) {
        return;
      }
      this.$router.push({
        path: path,
      });
    },
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 46.25rem;
}
.tablist {
  display: flex;
  align-items: center;
  height: 14rem;
  width: 37.5rem;
  margin: 0 auto;
  justify-content: space-between;
}
.tab {
  cursor: pointer;
  font-size: 1.25rem;
  color: #000000ff;
  opacity: 0.6;
  
}
.selecttab {
  position: relative;
  opacity: 1;
}
.selecttab::after {
  content: "";
  position: absolute;
  width: 3.125rem;
  height: 0.375rem;
  background: #60a6ec;
  left: 50%;
  transform: translateX(-50%);
 bottom: -0.625rem;
}
.product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.875rem;
  margin-bottom: 10rem;
  box-sizing: border-box;
  flex-wrap: wrap;
}
.product_item {
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 3.125rem;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  background: #f1f1f1ff;
}
.title {
  font-size: 2.375rem;
  color: #000000ff;
  font-weight: bold;
}
.sub {
  font-size: 1.5rem;
  color: #333333ff;
  margin: 0.8125rem 0 0.75rem;
}
.more {
  display: flex;
  align-items: center;
  font-size: 1.25rem;
  margin-bottom: 0.5625rem;
  color: #448ef7ff;
  cursor: pointer;
}
.more_icon {
  width: 0.5625rem;
  height: 0.9375rem;
  margin-left: 0.9375rem;
}
.pimg {
  width: 54.6875rem;
  height: 32.5rem;
}
.item {
  width: 57.1875rem;
  height: 45.625rem;
  margin-bottom: 1.875rem;
}
</style>