<template>
  <div>
    <div class="detail">
      <img src="@/assets/product/student/img1.png" class="detail_img" />
      <div :class="['detail_text', 'gradient_right']">
        <div class="detail_title">智能电子学生证</div>
        <div class="detail_sub">让孩子可以随时随地联系家人</div>
      </div>
      <div :class="['detail_tab', show1 ? 'gradient_right' : '']">
        <div class="detail_single" v-for="item in category" :key="item.name">
          <img :src="item.img" class="detail_single_icon" />
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/student/img2.png"
        class="detail_img"
        ref="targetElement"
      />
      <div :class="['detail_text2', show2 ? 'gradient_left' : '']">
        <div class="detail_title">实时精准定位</div>
        <div class="detail_sub2">
          北斗+GPS+LBS+Wi-Fi四重实时定位，实时查看孩子位置
        </div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/student/img3.png"
        class="detail_img"
        ref="targetElement2"
      />
      <div :class="['detail_text3', show3 ? 'gradient_right' : '']">
        <div class="detail_title">双向通话</div>
        <div class="detail_sub2">
          按键拨打小程序预置手机号码进行双向语音通话，支持三大运营商4G全网通信，通话质量稳定
        </div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/student/img4.png"
        class="detail_img"
        ref="targetElement3"
      />
      <div :class="['detail_text4', show4 ? 'gradient_top' : '']">
        <div class="detail_title">SOS紧急呼叫</div>
        <div class="detail_sub2">
          一键SOS紧急呼叫，自动向呼救对象推送实时位置信息
        </div>
      </div>
    </div>
    <div class="parameter">
      <div class="parameter_title">产品参数</div>
      <div v-for="(item, index) in argumentsList" :key="index">
        <div class="parameter_tab">{{ item.name }}</div>
        <div
          class="parameter_item"
          v-for="citem in item.list"
          :key="citem.name"
        >
          <div class="parameter_item_title">{{ citem.name }}</div>
          <div class="parameter_item_content">{{ citem.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
import icon1 from "../../assets/product/student/icon1.png";
import icon2 from "../../assets/product/student/icon2.png";
import icon3 from "../../assets/product/student/icon3.png";
import icon4 from "../../assets/product/student/icon4.png";
import icon5 from "../../assets/product/student/icon5.png";
import icon6 from "../../assets/product/student/icon6.png";
export default {
  data() {
    return {
      category: [
        {
          name: "全网通4G",
          img: icon1,
        },
        {
          name: "精准定位",
          img: icon2,
        },
        {
          name: "双向通话",
          img: icon3,
        },
        {
          name: "高清彩屏",
          img: icon4,
        },
        {
          name: "多人监护",
          img: icon5,
        },
        {
          name: "SOS报警",
          img: icon6,
        },
      ],
      argumentsList: [
        {
          name: "规格参数",
          list: [
            {
              name: "产品名称与型号",
              content: "智能电子学生证ESC-A1",
            },
            {
              name: "屏幕",
              content: "1.54寸触摸屏",
            },
            {
              name: "芯片",
              content: "紫光展锐 8910S",
            },
            {
              name: "网络",
              content: "中国移动、中国联通、中国电信、中国广电",
            },
            {
              name: "尺寸",
              content: "107mm（长）*59mm（宽）*10mm（厚）",
            },
            {
              name: "重量",
              content: "64.8g",
            },

            {
              name: "定位",
              content: "GPS、北斗、Wi-Fi、LBS",
            },
            {
              name: "SIM",
              content: "Nano SIM",
            },
            {
              name: "电池",
              content: "1000mAH",
            },
            {
              name: "RFID",
              content: "2.4G/13.56M",
            },
            {
              name: "按键",
              content: "5个实体按键",
            },
            {
              name: "充电口",
              content: "防水Type-C",
            },
            {
              name: "语音",
              content: "麦克风&扬声器",
            },
            {
              name: "传感器",
              content: "重力加速度传感器",
            },
            {
              name: "ID卡",
              content: "便准ID尺寸证件照",
            },
            {
              name: "工作时间",
              content: "7-10天",
            },
            {
              name: "定制",
              content: "支持OEM，ODM定制",
            },
          ],
        },
      ],
      show1: false,
      show2: false,
      show3: false,
      show4: false,
    };
  },
  directives: {
    ScrollTo: VueScrollTo.directive,
  },
  mounted() {
       this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    setTimeout(() => {
      this.show1 = true;
    }, 1000);
  },

  methods: {
    // 获取页面滚动距离
    handleScroll() {
      const element = this.$refs.targetElement;
      const element2 = this.$refs.targetElement2;
      const element3 = this.$refs.targetElement3;

      const rect = element.getBoundingClientRect();
      const rect2 = element2.getBoundingClientRect();
      const rect3 = element3.getBoundingClientRect();

      if (rect.top <= window.innerHeight && rect.bottom <= 1500) {
        // 元素在可视区域内
        if (!this.show2) {
          this.show2 = true;
        }
      }

      if (rect2.top <= window.innerHeight && rect2.bottom <= 1500) {
        if (!this.show3) {
          this.show3 = true;
        }
      }
      if (rect3.top <= window.innerHeight && rect3.bottom <= 1500) {
        if (!this.show4) {
          this.show4 = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.detail {
  width: 100%;
  height: 67.5rem;
  position: relative;
}
.detail_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.detail_text {
  top: 22.8125rem;
  right: 33.125rem;
  position: absolute;
  color: white;
  opacity: 0;
}
.detail_title {
  font-size: 5rem;
  font-weight: bold;
  text-align: left;
}
.detail_sub {
  font-size: 2rem;
  margin-top: 1.25rem;
  text-align: left;
}
.detail_tab {
  position: absolute;
  opacity: 0;
  bottom: 9.75rem;
  right: 36.875rem;
  width: 31.25rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.detail_single {
  display: flex;
  align-items: center;
  flex: 0 0 48%;
  font-size: 1.5rem;
  color: white;
  margin-bottom: 2.5rem;
}
.detail_single_icon {
  width: 2.75rem;
  height: 2.75rem;
  margin-right: 1rem;
}
.detail_text2 {
  color: white;
  position: absolute;
  opacity: 0;
  left: 9.125rem;
  top: 28.9375rem;
}
.detail_text3 {
  position: absolute;
  opacity: 0;
  top: 27.6875rem;
  right: 14.125rem;
}
.detail_sub2 {
  font-size: 1.375rem;
  margin-top: 1.25rem;
  width: 35.75rem;
  text-align: left;
}
.detail_text4 {
  position: absolute;
  opacity: 0;
  top: 8.75rem;
  width: 100%;
}
.detail_text4 div {
  width: 100%;
  text-align: center;
}
/* 产品参数 */
.parameter_title {
  width: 100%;
  line-height: 13.75rem;
  height: 13.75rem;
  padding-left: 9.125rem;
  box-sizing: border-box;
  font-size: 3.75rem;
  text-align: left;
  color: #000000ff;
  font-weight: bold;
}
.parameter_tab {
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  padding-left: 8.75rem;
  box-sizing: border-box;
  background: #fafafa;
  text-align: left;
  font-size: 1.75rem;
  color: #000000ff;
}
.parameter_item {
  width: 101.75rem;
  margin: 0 auto;
  border-bottom: 0.0625rem solid #f5f5f5ff;
  display: flex;
  justify-content: flex-start;
  min-height: 4.5rem;
  align-items: center;
}
.parameter_item_title {
  width: 29.625rem;
  font-size: 1.25rem;
  color: #797979ff;
  text-align: left;
}
.parameter_item_content {
  max-width: 71.75rem;
  font-size: 1.25rem;
  color: #333333ff;
  text-align: left;
}
</style>
