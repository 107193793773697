<template>
  <div>
    <img src="@/assets/solution/img1.png" class="banner" />
    <div class="content">
      <div class="title">方案概述</div>
      <div class="sub">
智慧园区解决方案由智慧园区运营中心（大屏展示），人员管理、安防监控、智能门禁、智慧停车场、智慧餐饮、设备管理六大子系统构成，覆盖综合管理、安全管理、运营管理、数据管理四大能力中台，多维度构建丰富、高效、安全的智能化园区服务。
      </div>
    </div>
    <img src="@/assets/solution/img2.png" class="blueprint" />
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.banner {
  width: 100%;
  height: 46.25rem;
}
.title {
  font-size: 3.25rem;
  color: #333333ff;
  text-align: left;
  font-weight: bold;
}
.content {
  width: 100%;
  padding: 8.75rem 0 8.75rem 9.125rem;
  box-sizing: border-box;
}
.sub {
  width: 101.25rem;
  margin-top: 2.75rem;
  font-size: 1.25rem;
  text-align: left;
}
.blueprint {
  width: 100%;
  height: 67.5rem;
}
</style>