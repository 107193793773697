import { render, staticRenderFns } from "./rc20.vue?vue&type=template&id=6183c06e&scoped=true&"
import script from "./rc20.vue?vue&type=script&lang=js&"
export * from "./rc20.vue?vue&type=script&lang=js&"
import style0 from "./rc20.vue?vue&type=style&index=0&id=6183c06e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6183c06e",
  null
  
)

export default component.exports