<template>
  <div>
    <img src="@/assets/mobile/solution/img3.jpg" class="banner" />
    <span class="park_title">智慧校园解决方案</span>
    <div class="content">
      <div class="title">方案概述</div>
      <div class="sub">
        智慧校园解决方案基于学校现有监控建设，充分利用已有摄像头、新增门禁终端、电子学生证，对监控内容进行AI赋能，通过对摄像头采集到的图像的分析和识别，门禁通行与控制，个人轨迹跟踪，来满足孩、家、校特定管理和安全功能需求的智慧校园系统。
      </div>
    </div>

    <img src="@/assets/mobile/solution/img4.jpg" class="blueprint" />
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.banner {
  width: 100%;
  height: 26rem;
}
.park_title {
  position: absolute;
  top: 12.125rem;
  left: 0;
  right: 0;
  font-size: 1.6875rem;
  color: #ffffff;
}
.title {
  font-size: 1.4375rem;
  color: #333333ff;
  text-align: left;
  font-weight: bold;
}
.content {
  width: 100%;
  padding: 2.625rem 1.9375rem;
  box-sizing: border-box;
}
.sub {
  width: 20rem;
  margin-top: 1.3125rem;
  font-size: 0.9375rem;
  text-align: left;
}

.blueprint {
  width: 100%;
  height: 19.25rem;
}
</style>