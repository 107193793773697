<template>
  <div>
    <div class="detail_item">
      <img src="@/assets/mobile/product/rc13/1.jpg" class="detail_item_img" />
      <div :class="['detail_text', 'gradient_bottom']">
        <div class="detail_title">证码通RC13</div>
        <div class="detail_title">人脸识别智能核验终端</div>
        <div class="detail_tips">考勤管理 | 访客管理 | 电子门禁</div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement">
      <img src="@/assets/mobile/product/rc13/2.jpg" class="detail_item_img" />
      <div :class="['detail_text2', show1 ? 'gradient_left' : '']">
        <div class="detail_title2">多证可读</div>
        <div class="detail_tips2">
          支持读取ISO/IEC
          14443A、14443B、Mifare等非接触卡,TypeA卡/TypeB卡及电子标签
        </div>
      </div>
      <div :class="['detail_ul', show1 ? 'gradient_left' : '']">
        <div class="detail_li">身份证</div>
        <div class="detail_li">港澳台居民居住证</div>
        <div class="detail_li">外国人永久居留证</div>
        <div class="detail_li">eID电子证件</div>
        <div class="detail_li">护照</div>
        <div class="detail_li">老年卡</div>
        <div class="detail_li">市政卡</div>
        <div class="detail_li">公交卡</div>
        <div class="detail_li">学生卡</div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement2">
      <img src="@/assets/mobile/product/rc13/3.jpg" class="detail_item_img" />
      <div :class="['detail_text3', show2 ? 'gradient_top' : '']">
        <div class="detail_title3">快速扫码 0.3s精准识别</div>
        <div class="detail_tips3">支持识读一维码、二维码,支持码型包括</div>
        <div class="detail_tips3">一维码:EAN13、EAN8、 Code128等</div>
        <div class="detail_tips3">二维码:QR Code、PDF417等</div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement3">
      <img src="@/assets/mobile/product/rc13/4.jpg" class="detail_item_img" />
      <div :class="['detail_text4', show3 ? 'gradient_left' : '']">
        <div class="detail_title4">高效人脸识别</div>
        <div class="detail_tips4">支持人脸活体识别、照片防骗本地人脸白名单</div>
        <div class="detail_tips4">支持本地人脸特征库存储不少于1万条</div>
        <div class="detail_tips4">支持人证比对,人证核验速度&lt;2s</div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement4">
      <img src="@/assets/mobile/product/rc13/5.jpg" class="detail_item_img" />
      <div :class="['detail_text5', show4 ? 'gradient_top' : '']">
        <div class="detail_title3">开放接口,支持应用扩展</div>
        <div class="detail_tips3">可接入门禁通道、考勤系统等第三方系统</div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement5">
      <img src="@/assets/mobile/product/rc13/6.jpg" class="detail_item_img" />
      <div :class="['detail_text6', show5 ? 'gradient_left' : '']">桌面式</div>
    </div>
    <div class="detail_item2" ref="targetElement6">
      <img src="@/assets/mobile/product/rc13/7.jpg" class="detail_item_img" />
      <div :class="['detail_text6', show6 ? 'gradient_left' : '']">立柱式</div>
    </div>
    <div class="detail_item2" ref="targetElement7">
      <img src="@/assets/mobile/product/rc13/8.jpg" class="detail_item_img" />
      <div :class="['detail_text6', show7 ? 'gradient_left' : '']">闸机式</div>
    </div>

    <!-- 产品参数 -->
    <div class="parameter">
      <div class="parameter_title">产品参数</div>
      <div v-for="(item, index) in parameter" :key="index">
        <div class="parameter_tab">{{ item.title }}</div>
        <div
          class="parameter_item"
          v-for="citem in item.chirdren"
          :key="citem.name"
        >
          <div class="parameter_item_name">{{ citem.name }}</div>
          <div class="parameter_item_anw">{{ citem.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
export default {
  data() {
    return {
      parameter: [
        {
          title: "常规参数",
          chirdren: [
            {
              name: "产品名称与型号",
              content: "证码通人脸识别终端RC13",
            },
            {
              name: "系统",
              content: "Android7.1及以上存储2GRAM+8GROM",
            },
            {
              name: "网络",
              content:
                "4G全网通(移动、联通、电信)Wi-Fi 2.4GHzIEEE802.11b/g/n; 以太网",
            },
            {
              name: "尺寸",
              content: "133mm(长)*120.2mm(宽)*361mm(高)",
            },
            {
              name: "安装方式",
              content: "立式支架、闸机通道、壁挂、桌面支架(选配)",
            },
          ],
        },
        {
          title: "摄像头",
          chirdren: [
            {
              name: "分辨率",
              content: "200万像素,1920*1080",
            },
            {
              name: "类型",
              content: "双目宽动态摄像头,IR、RGB双重采集人脸图像",
            },
            {
              name: "人脸识别",
              content: "支持活体人脸识别,视频、照片防骗",
            },
            {
              name: "补光灯",
              content: "红外双补光灯",
            },
          ],
        },
        {
          title: "屏幕",
          chirdren: [
            {
              name: "规格",
              content: "8寸IPS高清触摸屏(抗UV)",
            },
            {
              name: "分辨率",
              content: "800*1280",
            },
            {
              name: "触摸",
              content:
                "支持多点触摸,⽀持湿⼿、⼿套操作,采⽤防油渍指纹涂层(选配)",
            },
          ],
        },
        {
          title: "扫码模块",
          chirdren: [
            {
              name: "图像传感器",
              content: "640*480 CMOS",
            },
            {
              name: "支持码型",
              content:
                "一维码:\nEAN13、EAN8、UPC-A、UPC-E0、UPC-E1、Code128、Code39、Code93、CodaBar 、Interleaved2of5、Industrial25、Matrix2of5、Code11、MSIPlessey、RSS-14、限定式 RSS、扩展式RSS\n二维码:\nQR Code、PDF417、DataMatrix(ECC200)",
            },
          ],
        },
        {
          title: "读证模块",
          chirdren: [
            {
              name: "读卡类型",
              content:
                "ISO/IEC14443A、14443B、Mifare等非接触卡TypeA卡/ TypeB卡及电子标签,包含身份证、港澳台居民居住证、外国人永久居留证、公交卡、市政卡、老年卡、学生卡、护照、elD电子证件等。支持SAM_II,SAM_III",
            },
            {
              name: "识别距离",
              content: "0-3cm",
            },
            {
              name: "读卡时间",
              content: "≤2秒",
            },
          ],
        },
        {
          title: "接口",
          chirdren: [
            {
              name: "电源",
              content: "1路DC12V2A电源",
            },
            {
              name: "以太网",
              content: "1路RJ45",
            },
            {
              name: "RS232",
              content: "1路串口通讯接口",
            },
            {
              name: "USB",
              content: "2路USB标准A口 ",
            },
          ],
        },
        {
          title: "工作温度/湿度",
          chirdren: [
            {
              name: "工作温度/存放温度",
              content: "0℃~50℃/-40℃~70℃",
            },
            {
              name: "工作湿度/存放湿度",
              content: "5%~95%非凝结",
            },
            {
              name: "RS232",
              content: "1路串口通讯接口",
            },
          ],
        },
        {
          title: "装箱清单",
          chirdren: [
            {
              name: "",
              content: "主机*1、电源适配器*1、Wi-Fi天线*1、4G天线*2",
            },
          ],
        },
      ],
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
    };
  },
  directives: {
    ScrollTo: VueScrollTo.directive,
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll2);
    });
  },
  beforeDestroy() {
    windowNaNpxoveEventListener("scroll", this.handleScroll2);
  },
  methods: {
    // 获取页面滚动距离
    handleScroll2() {
      const element = this.$refs.targetElement;
      const element2 = this.$refs.targetElement2;
      const element3 = this.$refs.targetElement3;
      const element4 = this.$refs.targetElement4;
      const element5 = this.$refs.targetElement5;
      const element6 = this.$refs.targetElement6;
      const element7 = this.$refs.targetElement7;

      const rect = element.getBoundingClientRect();
      const rect2 = element2.getBoundingClientRect();
      const rect3 = element3.getBoundingClientRect();
      const rect4 = element4.getBoundingClientRect();
      const rect5 = element5.getBoundingClientRect();
      const rect6 = element6.getBoundingClientRect();
      const rect7 = element7.getBoundingClientRect();

      if (rect.top <= window.innerHeight && rect.bottom <= 1200) {
        // 元素在可视区域内
        if (!this.show1) {
          this.show1 = true;
        }
      }

      if (rect2.top <= window.innerHeight && rect2.bottom <= 1200) {
        if (!this.show2) {
          this.show2 = true;
        }
      }
      if (rect3.top <= window.innerHeight && rect3.bottom <= 1200) {
        if (!this.show3) {
          this.show3 = true;
        }
      }
      if (rect4.top <= window.innerHeight && rect4.bottom <= 1200) {
        if (!this.show4) {
          this.show4 = true;
        }
      }
      if (rect5.top <= window.innerHeight && rect5.bottom <= 1200) {
        if (!this.show5) {
          this.show5 = true;
        }
      }
      if (rect6.top <= window.innerHeight && rect6.bottom <= 1200) {
        if (!this.show6) {
          this.show6 = true;
        }
      }
      if (rect7.top <= window.innerHeight && rect7.bottom <= 1200) {
        if (!this.show7) {
          this.show7 = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.detail_item {
  width: 100%;
  height: 36.6875rem;
  position: relative;
}
.detail_item2 {
  width: 100%;
  height: 33.125rem;
  position: relative;
}
.detail_item_img {
  width: 100%;
  height: 100%;
}
.detail_text {
  position: absolute;
  bottom: 3.75rem;
  opacity: 0;
  left: 0;
  width: 100%;
}
.detail_title {
  font-size: 1.6875rem;
  color: white;
  font-weight: bold;
}
.detail_tips {
  font-size: 0.9375rem;
  color: white;
  margin-top: 1.1875rem;
}
.detail_text2 {
  position: absolute;
  top: 1.875rem;
  left: 1.9375rem;
  opacity: 0;
}
.detail_title2 {
  font-size: 1.6875rem;
  color: #000;

  font-weight: bold;
  text-align: left;
}
.detail_tips2 {
  width: 19.5rem;
  font-size: 0.9375rem;
  color: #000;
  margin-top: 0.625rem;
  text-align: left;
}
.detail_ul {
  position: absolute;
  opacity: 0;
  left: 2.75rem;
  bottom: 2.5rem;
}
.detail_li {
  font-size: 0.875rem;
  color: #333333;
  margin-top: 0.9375rem;
  text-align: left;
  position: relative;
}
.detail_li::after {
  content: "";
  position: absolute;
  left: -0.9375rem;
  top: 50%;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background: #333333;
  transform: translateY(-50%);
}
.detail_text3 {
  position: absolute;
  width: 100%;
  opacity: 0;
  top: 2.625rem;
  left: 0;
}
.detail_title3 {
  font-size: 1.6875rem;
  color: white;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1.1875rem;
}
.detail_tips3 {
  width: 100%;
  font-size: 0.9375rem;
  color: white;
  text-align: center;
}
.detail_text4 {
  position: absolute;
  left: 1.9375rem;
  top: 1.875rem;
  opacity: 0;
}
.detail_title4 {
  font-size: 1.6875rem;
  color: #000;
  margin-bottom: 1.1875rem;
  font-weight: bold;
  text-align: left;
}
.detail_tips4 {
  font-size: 0.9375rem;
  width: 11.8125rem;
  color: #000;
  margin-bottom: 0.625rem;
  text-align: left;
}
.detail_text5 {
  position: absolute;
  width: 100%;
  top: 3.875rem;
  opacity: 0;
  left: 0;
}
.detail_text6 {
  position: absolute;
  left: 1.9375rem;
  top: 3.875rem;
  opacity: 0;
  font-size: 1.6875rem;
  font-weight: bold;
  color: #000;
}

/* 产品参数 */
.parameter {
  margin-top: 2.625rem;
}
.parameter_title {
  font-size: 1.4375rem;
  color: #000;
  font-weight: bold;
  padding-left: 1.3125rem;
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 1.3125rem;
}
.parameter_tab {
  width: 100%;
  height: 3.5625rem;
  background: #fafafa;
  text-align: left;
  font-size: 1.0625rem;
  padding-left: 1.3125rem;
  font-weight: bold;
  color: #000;
  box-sizing: border-box;
  line-height: 3.5625rem;
}
.parameter_item {
  width: 100%;
  padding: 1.3125rem 0;
  display: flex;
  align-items: center;
  border-bottom: 0.0625rem solid #dddddd;
}
.parameter_item_name {
  width: 4.5625rem;
  height: 100%;
  margin-right: 2.625rem;
  margin-left: 1.3125rem;
  box-sizing: border-box;
  text-align: left;
  font-size: 0.9375rem;
  color: #797979;
}
.parameter_item_anw {
  width: 13.75rem;
  word-wrap: break-word;
  white-space: pre-line;
  font-size: 0.9375rem;
  text-align: left;
  color: #333333;
}
</style>