<template>
  <div>
    <div class="detail_text">
      <img src="@/assets/mobile/product/at21/1.jpg" class="detail_img" />
      <div :class="['detail_item', 'gradient_top']">
        <div class="detail_title">AT-21</div>
        <div class="detail_title">智能发卡收银一体机</div>
      </div>
    </div>
    <div class="detail_text2" ref="targetElement">
      <img src="@/assets/mobile/product/at21/2.jpg" class="detail_img" />
      <div :class="['detail_item2', show1 ? 'gradient_top' : '']">
        <div class="detail_title2">高清人脸识别</div>
        <div class="detail_tips2">搭载高清前置摄像头</div>
        <div class="detail_tips2">支持高清人脸识别、人证比对</div>
      </div>
    </div>
    <div class="detail_text2" ref="targetElement2">
      <img src="@/assets/mobile/product/at21/3.jpg" class="detail_img" />
      <div :class="['detail_item3', show2 ? 'gradient_left' : '']">
        <div class="detail_title3">多身份识别</div>
        <div class="detail_tips3">支持读取多种证件识别身份信息</div>
      </div>
      <div :class="['detail_ul', show2 ? 'gradient_left' : '']">
        <div class="detail_li">居民身份证</div>
        <div class="detail_li">港澳台居住证</div>
        <div class="detail_li">外国人永久居留证</div>
        <div class="detail_li">护照</div>
      </div>
    </div>
    <div class="detail_text2" ref="targetElement3">
      <img src="@/assets/mobile/product/at21/4.jpg" class="detail_img" />
      <div :class="['detail_item4', show3 ? 'gradient_top' : '']">
        <div class="detail_title4">高速打印小票</div>
        <div class="detail_tips4">可打印收银小票、凭条、业务流水票据等</div>
      </div>
    </div>

    <!-- 产品参数 -->
    <div class="parameter">
      <div class="parameter_title">产品参数</div>
      <div v-for="(item, index) in parameter" :key="index">
        <div class="parameter_tab">{{ item.title }}</div>
        <div
          class="parameter_item"
          v-for="citem in item.chirdren"
          :key="citem.name"
        >
          <div class="parameter_item_name">{{ citem.name }}</div>
          <div class="parameter_item_anw">{{ citem.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
export default {
  data() {
    return {
      parameter: [
        {
          title: "规格参数",
          chirdren: [
            {
              name: "产品名称与型号",
              content: "智能发卡收银一体机AT21",
            },
            {
              name: "屏幕",
              content: "10.1英寸屏幕,分辨率为1280x800,支持多点触摸",
            },
            {
              name: "CPU",
              content: "ARM Cortex-A17四核1.8GHz",
            },
            {
              name: "操作系统",
              content: "Android 7.1 SrTi安全操作系统",
            },
            {
              name: "内存",
              content: "RAM:2GB+ROM:8GB\nRAM:2GB+ROM: 16GB (可选)",
            },
            {
              name: "Wi-Fi",
              content: "WIFI2.4GHz支持IEEE802.11 b/g/n",
            },
            {
              name: "摄像头",
              content:
                "双500万摄像头:前置摄像头:用于人脸识别,扫码:支持条形码,二维码",
            },
            {
              name: "二维码读取速度",
              content: "200次/秒",
            },
            {
              name: "身份证读取速度",
              content: "<2秒",
            },
            {
              name: "NFC",
              content: "可读身份证、读取护照",
            },
            {
              name: "发卡模块",
              content:
                "新卡槽可容纳150张,废卡槽可容纳20张支持卡协议:ISO7816接触卡标准,ISO14443非接触卡标准",
            },
            {
              name: "接口",
              content: "DC电源接口、USB TYPE-A、USB TYPE-B、DVI接口、RJ-45",
            },
            {
              name: "打印机",
              content: "58热敏打印机",
            },
            {
              name: "电源",
              content: "适配器供电AC100V~220V/2A~DC24V/2.7A DC",
            },
            {
              name: "以太网",
              content: "RJ45接口,10/100M",
            },
            {
              name: "工作环境",
              content: "工作温度: 0°C~40°C\n存储温度: -20°C~60°C",
            },
            {
              name: "尺寸",
              content: "400mm(长)*300mm(宽)*324mm(高)",
            },
            {
              name: "重量",
              content: "3.6kg",
            },
          ],
        },
      ],
      show1: false,
      show2: false,
      show3: false,
    };
  },
  directives: {
    ScrollTo: VueScrollTo.directive,
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    windowNaNpxoveEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 获取页面滚动距离
    handleScroll() {
      const element = this.$refs.targetElement;
      const element2 = this.$refs.targetElement2;
      const element3 = this.$refs.targetElement3;
      const rect = element.getBoundingClientRect();
      const rect2 = element2.getBoundingClientRect();
      const rect3 = element3.getBoundingClientRect();

      if (rect.top <= window.innerHeight && rect.bottom <= 1200) {
        if (!this.show1) {
          this.show1 = true;
        }
      }

      if (rect2.top <= window.innerHeight && rect2.bottom <= 1200) {
        if (!this.show2) {
          this.show2 = true;
        }
      }
      if (rect3.top <= window.innerHeight && rect3.bottom <= 1200) {
        if (!this.show3) {
          this.show3 = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.detail_text {
  width: 100%;
  height: 36.6875rem;
  position: relative;
}
.detail_text2 {
  width: 100%;
  height: 33.125rem;
  position: relative;
}
.detail_img {
  width: 100%;
  height: 100%;
}
.detail_item {
  position: absolute;
  top: 7.4375rem;
  left: 0;
  opacity: 0;
  width: 100%;
}
.detail_title {
  font-size: 1.6875rem;
  color: #000;
  font-weight: bold;
}
.detail_item2 {
  position: absolute;
  opacity: 0;
  top: 3.875rem;
  left: 0;
  width: 100%;
}
.detail_title2 {
  font-size: 1.6875rem;
  color: white;
  font-weight: bold;
  margin-bottom: 1.1875rem;
}
.detail_tips2 {
  font-size: 0.9375rem;
  color: white;
}
.detail_item3 {
  position: absolute;
  opacity: 0;
  top: 3.75rem;
  left: 1.9375rem;
}
.detail_title3 {
  font-size: 1.6875rem;
  text-align: left;
  color: #000;
  font-weight: bold;
}
.detail_tips3 {
  font-size: 0.9375rem;
  text-align: left;
  color: #000;
  margin-top: 1.1875rem;
}
.detail_ul {
  position: absolute;
  top: 11.4375rem;
  left: 2.75rem;
  opacity: 0;
}
.detail_li {
  font-size: 0.875rem;
  color: #333333;
  text-align: left;
  position: relative;
  margin-bottom: 0.9375rem;
}
.detail_li::after {
  position: absolute;
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  left: -0.9375rem;
  background: #333333;
}
.detail_item4 {
  position: absolute;
  opacity: 0;
  top: 3.875rem;
  left: 0;
  width: 100%;
}
.detail_title4 {
  font-size: 1.6875rem;
  color: white;
  margin-bottom: 1.1875rem;
  font-weight: bold;
}
.detail_tips4 {
  font-size: 0.9375rem;
  color: white;
}

.parameter {
  margin-top: 2.625rem;
}
.parameter_title {
  font-size: 1.4375rem;
  color: #000;
  font-weight: bold;
  padding-left: 1.3125rem;
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 1.3125rem;
}
.parameter_tab {
  width: 100%;
  height: 3.5625rem;
  background: #fafafa;
  text-align: left;
  font-size: 1.0625rem;
  padding-left: 1.3125rem;
  font-weight: bold;
  color: #000;
  box-sizing: border-box;
  line-height: 3.5625rem;
}
.parameter_item {
  width: 100%;
  padding: 1.3125rem 0;
  display: flex;
  align-items: center;
  border-bottom: 0.0625rem solid #dddddd;
}
.parameter_item_name {
  width: 4.5625rem;
  height: 100%;
  margin-right: 2.625rem;
  margin-left: 1.3125rem;
  box-sizing: border-box;
  text-align: left;
  font-size: 0.9375rem;
  color: #797979;
}
.parameter_item_anw {
  width: 13.75rem;
  word-wrap: break-word;
  white-space: pre-line;
  font-size: 0.9375rem;
  text-align: left;
  color: #333333;
}
</style>
