<template>
  <div>
    <div class="banner">
      <img src="@/assets/about/banner.png" />
    </div>
    <div class="tab">
      <div
        v-for="item in tabList"
        :key="item.index"
        @click="switcTab(item.index)"
        :class="[selectIndex == item.index ? 'selecttab' : 'notab']"
      >
        {{ item.name }}
      </div>
    </div>

    <!-- 公司简介 -->
    <Synopsis v-if="selectIndex == 0" /> 
    <!-- 发展历程 -->
    <Progress v-if="selectIndex == 1" />
    <!-- 联系我们 -->
    <Contact v-if="selectIndex == 2" />
  </div>
</template>

<script>
import Synopsis from "./about/synopsis.vue";
import Progress from "./about/progress.vue";
import Contact from "./about/contact.vue";
export default {
  data() {
    return {
      selectIndex: 0,
      tabList: [
        {
          name: "公司简介",
          index: 0,
        },
        {
          name: "发展历程",
          index: 1,
        },
        {
          name: "联系我们",
          index: 2,
        },
      ],
    };
  },

  components: {
    Synopsis,
    Progress,
    Contact,
  },

  methods: {
    // 切换tab
    switcTab(index) {
      this.selectIndex = index;
    },
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 46.25rem;
  position: relative;
}
.banner img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.tab {
  width: 31.5rem;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3.75rem auto 8.5rem;
  color: #000000ff;
}
.tab div {
  cursor: pointer;
}
.selecttab {
  position: relative;
}
.selecttab::after {
  content: "";
  width: 1.875rem;
  height: 0.375rem;
  position: absolute;
  background: #60a6ec;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.625rem;
}
.notab {
  opacity: 0.6;
}
</style>
