<template>
  <div>
    <div class="detail">
      <img src="@/assets/product/rc14/banner.png" class="detail_img" />
      <div :class="['detail_text', 'gradient_left']">
        <div class="detail_title">实名制平板RC14</div>
        <div class="detail_sub">多屏互动，一触即达</div>
      </div>
      <div :class="['detail_category', show1 ? 'gradient_left' : '']">
        <div v-for="item in category" :key="item.name" class="category">
          <img :src="item.img" class="category_icon" />
          <div class="category_name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/rc14/img1.png"
        class="detail_img"
        ref="targetElement"
      />
      <div class="detail_content">
        <div :class="['t1', show2 ? 'gradient_bottom' : '']">
          <img src="@/assets/product/rc14/t1.png" class="detail_content_img" />
          <div class="detail_content_name">高清人摄</div>
          <div class="detail_content_sub">1080P翻转式高清人摄</div>
          <div class="detail_content_sub">精准人像采集</div>
        </div>
        <div :class="['t1', show3 ? 'gradient_bottom' : '']">
          <img src="@/assets/product/rc14/t2.png" class="detail_content_img" />
          <div class="detail_content_name">文稿扫描</div>
          <div class="detail_content_sub">超清扫描摄像头</div>
          <div class="detail_content_sub">纸质文稿电子化显示</div>
        </div>
        <div :class="['t1', show4 ? 'gradient_bottom' : '']">
          <img src="@/assets/product/rc14/t3.png" class="detail_content_img" />
          <div class="detail_content_name">灵活影柱</div>
          <div class="detail_content_sub">影柱可折叠</div>
          <div class="detail_content_sub">拔插主机便携随心用</div>
        </div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/rc14/img2.png"
        class="detail_img"
        ref="targetElement2"
      />
      <div :class="['detail_text3', show5 ? 'gradient_right' : '']">
        <div class="detail_title">超清大屏</div>
        <div class="detail_other">
          <div class="detail_sub2">
            <div class="dian"></div>
            <div class="detail_other_text">
              精细画质：<span>高清分辨率1920*1200</span>
            </div>
          </div>
          <div class="detail_sub2">
            <div class="dian"></div>
            <div class="detail_other_text">
              多点触屏：<span>手写、笔写行云流水</span>
            </div>
          </div>
          <div class="detail_sub2">
            <div class="dian"></div>
            <div class="detail_other_text">
              真彩显示：<span>色彩生动鲜明</span>
            </div>
          </div>
          <div class="detail_sub2">
            <div class="dian"></div>
            <div class="detail_other_text">
              10.1英寸：<span>舒适观享体验</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/rc14/img3.png"
        class="detail_img"
        ref="targetElement3"
      />
      <div :class="['detail_text4', show6 ? 'gradient_top' : '']">
        <div class="detail_title">多屏互动 一触即达</div>
        <div class="detail_other2">
          <div class="detail_sub3">支持无线投屏，有线HDMI接口投屏</div>
          <div class="detail_sub3">
            音频跟随投屏设备播放，打破边界创造更多协作空间
          </div>
        </div>
      </div>
      <div :class="['detail_pro', show7 ? 'gradient_bottom' : '']">
        <div>
          <img src="@/assets/product/rc14/f1.png" class="detail_pro_icon" />
          <div class="detail_pro_text">投屏电脑</div>
        </div>
        <div>
          <img src="@/assets/product/rc14/f2.png" class="detail_pro_icon" />
          <div class="detail_pro_text">投屏大屏</div>
        </div>
        <div>
          <img src="@/assets/product/rc14/f3.png" class="detail_pro_icon" />
          <div class="detail_pro_text">投屏投影仪</div>
        </div>
      </div>
    </div>
    <div class="parameter">
      <div class="parameter_title">产品参数</div>
      <div v-for="(item, index) in argumentsList" :key="index">
        <div class="parameter_tab">{{ item.name }}</div>
        <div
          class="parameter_item"
          v-for="citem in item.list"
          :key="citem.name"
        >
          <div class="parameter_item_title">{{ citem.name }}</div>
          <div class="parameter_item_content">{{ citem.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
import icon1 from "../../assets/product/rc14/icon1.png";
import icon2 from "../../assets/product/rc14/icon2.png";
import icon3 from "../../assets/product/rc14/icon3.png";
import icon4 from "../../assets/product/rc14/icon4.png";
import icon5 from "../../assets/product/rc14/icon5.png";
import icon6 from "../../assets/product/rc14/icon6.png";
export default {
  data() {
    return {
      category: [
        {
          name: "读身份证",
          img: icon1,
        },
        {
          name: "文稿扫描",
          img: icon2,
        },
        {
          name: "扩展投屏",
          img: icon3,
        },
        {
          name: "人脸识别",
          img: icon4,
        },
        {
          name: "灵活笔写",
          img: icon5,
        },
        {
          name: "开放下载",
          img: icon6,
        },
      ],
      argumentsList: [
        {
          name: "规格参数",
          list: [
            {
              name: "产品名称与型号",
              content: "实名制终端RC14",
            },
            {
              name: "系统",
              content: "Android 7.1及以上",
            },
            {
              name: "CPU",
              content: "RK3288 四核Cortex-A17，主频最高达1.8GHz",
            },
            {
              name: "存储",
              content: "2+8G、2+16G（选配）、4+32G（选配）",
            },
            {
              name: "网络",
              content:
                "以太网百兆，WIFI2.4G， 支持IEEE 802.11 b/g/n ; WIFI  5G IEEE 802.11 a/b/g/n(选配)，4G 三网通 支持移动、联通、电信（选配）",
            },
            {
              name: "蓝牙",
              content: "蓝牙4.0(HS)支持BLE（选配）",
            },
            {
              name: "屏幕",
              content: "IPS 10.1⼨屏，分辨率1920x1080，亮度可调节",
            },
            {
              name: "触摸",
              content: "多点触摸电容屏 G+G，支持电容笔书写",
            },
            {
              name: "人像摄像头（平板）",
              content:
                "像素500W，焦距3.34±5%，场视角60°，视频分辨率1080P，30帧",
            },
            {
              name: "扫描摄像头（高拍）",
              content:
                "像素500W，焦距3.4±5%，场视角60°，视频分辨率1080P ，30帧，带补光灯",
            },
            {
              name: "最大扫描范围",
              content: "A4纸 (210mm*297mm)",
            },
            {
              name: "射频技术",
              content: "NFC 13.56MHz",
            },
            {
              name: "读证模块",
              content:
                "支持读取ISO/IEC 14443 非接触卡TypeA卡/TypeB卡，包含身份证、港澳台居民居住证、外国人永久居留证、公交卡、市政卡、老年卡、学生卡、护照、elD电子证件等",
            },
            {
              name: "接口",
              content:
                "USB TYPE-A *2、USB TYPE-C*1、DC供电接口*1、RJ45网口*1、HDMI*1、TF卡槽*1",
            },
            {
              name: "整机尺寸",
              content:
                "平板尺寸：265mm*210mm*44.85mm ；影柱：53.8mm*35.8mm*301.5mm",
            },
            {
              name: "工作环境",
              content: "工作温度：0°C～50 °C ，存储温度： - 20°C～60°C",
            },
          ],
        },
      ],
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
    };
  },

  directives: {
    ScrollTo: VueScrollTo.directive,
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  created() {
    setTimeout(() => {
      this.show1 = true;
    }, 1000);
  },

  methods: {
    // 获取页面滚动距离
    handleScroll() {
      const element = this.$refs.targetElement;
      const element2 = this.$refs.targetElement2;
      const element3 = this.$refs.targetElement3;

      const rect = element.getBoundingClientRect();
      const rect2 = element2.getBoundingClientRect();
      const rect3 = element3.getBoundingClientRect();

      if (rect.top <= window.innerHeight && rect.bottom <= 1500) {
        // 元素在可视区域内
        if (!this.show2) {
          this.show2 = true;
          setTimeout(() => {
            if (!this.show3) {
              this.show3 = true;
              setTimeout(() => {
                if (!this.show4) {
                  this.show4 = true;
                }
              }, 800);
            }
          }, 800);
        }
      }

      if (rect2.top <= window.innerHeight && rect2.bottom <= 1500) {
        if (!this.show5) {
          this.show5 = true;
        }
      }
      if (rect3.top <= window.innerHeight && rect3.bottom <= 1500) {
        if (!this.show6) {
          this.show6 = true;
          setTimeout(() => {
            if (!this.show7) {
              this.show7 = true;
            }
          }, 1000);
        }
      }
      //   if (rect4.top <= window.innerHeight && rect4.bottom <= 1500) {
      //     if (!this.show4) {
      //       this.show4 = true;
      //     }
      //   }
      //   if (rect5.top <= window.innerHeight && rect5.bottom <= 1500) {
      //     if (!this.show5) {
      //       this.show5 = true;
      //     }
      //   }
      //   if (rect6.top <= window.innerHeight && rect6.bottom <= 1500) {
      //     if (!this.show6) {
      //       this.show6 = true;
      //     }
      //   }
      //   if (rect7.top <= window.innerHeight && rect7.bottom <= 1500) {
      //     if (!this.show7) {
      //       this.show7 = true;
      //     }
      //   }
    },
  },
};
</script>

<style scoped>
.detail {
  width: 100%;
  height: 67.5rem;
  position: relative;
}
.detail5 {
  width: 100%;
  height: 1486px;
  position: relative;
}
.detail_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.detail_text {
  position: absolute;
  top: 21.25rem;
  opacity: 0;
  left: 9.125rem;
  color: white;
}
.detail_title {
  font-size: 5rem;
  font-weight: bold;
  text-align: left;
}
.detail_sub {
  font-size: 2rem;
  margin-top: 3.75rem;
  font-weight: bold;
  text-align: left;
}
.detail_category {
  position: absolute;
  bottom: 13.5rem;
  width: 40.625rem;
  left: 9.125rem;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.category {
  display: flex;
  flex: 0 0 33%;
  margin-bottom: 3.75rem;
  align-items: center;
}
.category_icon {
  width: 2.75rem;
  height: 2.75rem;
}
.category_name {
  font-size: 1.5rem;
  color: white;
  margin-left: 0.625rem;
}
.detail_content {
  width: 100%;
  height: 100%;
  padding: 0 9.125rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail_content_img {
  width: 32.5rem;
  height: 27.375rem;
}
.detail_content_name {
  font-size: 3rem;
  color: #000000ff;
  font-weight: bold;
  margin: 5.5rem 0 1.25rem;
}
.detail_content_sub {
  font-size: 1.375rem;
  color: #000000ff;
}
.detail_text3 {
  position: absolute;
  opacity: 0;
  top: 20.75rem;
  right: 19.625rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.dian {
  width: 0.875rem;
  height: 0.875rem;
  margin-right: 1.125rem;
  background: #000000;
  border-radius: 50%;
}
.detail_other {
  margin-top: 7.5625rem;
}
.detail_sub2 {
  display: flex;
  margin-bottom: 2.5rem;
  align-items: center;
}
.detail_other_text {
  font-size: 1.5rem;
  font-weight: bold;
}
.detail_other_text span {
  font-weight: normal;
}
/* 产品参数 */
.parameter_title {
  width: 100%;
  line-height: 13.75rem;
  height: 13.75rem;
  padding-left: 9.125rem;
  box-sizing: border-box;
  font-size: 3.75rem;
  text-align: left;
  color: #000000ff;
  font-weight: bold;
}
.parameter_tab {
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  padding-left: 8.75rem;
  box-sizing: border-box;
  background: #fafafa;
  text-align: left;
  font-size: 1.75rem;
  color: #000000ff;
}
.parameter_item {
  width: 101.75rem;
  margin: 0 auto;
  border-bottom: 0.0625rem solid #f5f5f5ff;
  display: flex;
  justify-content: flex-start;
  min-height: 4.5rem;
  align-items: center;
}
.parameter_item_title {
  width: 29.625rem;
  font-size: 1.25rem;
  color: #797979ff;
  text-align: left;
}
.parameter_item_content {
  max-width: 71.75rem;
  font-size: 1.25rem;
  color: #333333ff;
  text-align: left;
}
.detail_text4 {
  position: absolute;
  opacity: 0;
  width: 100%;
  top: 10rem;
  color: white;
}
.detail_text4 div {
  text-align: center;
}
.detail_other2 {
  margin-top: 1.25rem;
}
.detail_sub3 {
  font-size: 1.375rem;
}
.detail_pro {
  position: absolute;
  opacity: 0;
  bottom: 5.625rem;
  width: 100%;
  padding: 0 18.75rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail_pro_icon {
  width: 13.75rem;
  height: 13.6875rem;
}
.detail_pro_text {
  margin-top: 1.5625rem;
  font-size: 1.875rem;
  color: white;
  font-weight: bold;
}
.t1 {
  opacity: 0;
}
</style>
