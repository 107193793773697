<template>
  <div>
    <div class="detail">
      <img src="@/assets/product/rc13/img1.png" class="detail_img" />
      <div :class="['detail_text', 'gradient_right']">
        <div class="detail_title">证码通RC13</div>
        <div class="detail_title">人脸识别智能核验终端</div>
        <div class="detail_sub">考勤管理 | 访客管理 | 电子门禁</div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/rc13/img2.png"
        class="detail_img"
        ref="targetElement"
      />
      <div :class="['detail_text2', show1 ? 'gradient_left' : '']">
        <div class="detail_title">多证可读</div>
        <div class="detail_sub2">
          支持读取ISO/IEC
          14443A、14443B、Mifare等非接触卡，TypeA卡/TypeB卡及电子标签
        </div>

        <div class="detail_tab">
          <div>身份证</div>
          <div>港澳台居民居住证</div>
          <div>外国人永久居留证</div>
          <div>护照</div>
          <div>eID电子证件</div>
          <div>老年卡</div>
          <div>市政卡</div>
          <div>公交卡</div>
          <div>学生卡</div>
        </div>
      </div>
    </div>
    <div class="detail2">
      <img
        src="@/assets/product/rc13/img3.png"
        class="detail_img"
        ref="targetElement2"
      />
      <div :class="['detail_text3', show2 ? 'gradient_top' : '']">
        <div class="detail_title2">快速扫码 0.3s精准识别</div>
        <div class="detail_sub5">
          支持识读一维码、二维码，支持码型包括一维码：EAN13、EAN8、
          Code128等；二维码码型包括QR Code、PDF417等
        </div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/rc13/img4.png"
        class="detail_img"
        ref="targetElement3"
      />
      <div :class="['detail_text4', show3 ? 'gradient_left' : '']">
        <div class="detail_title">高效人脸识别</div>
        <div class="detail_orther">
          <div class="detail_sub4">
            支持人脸活体识别、照片防骗，本地人脸白名单,
          </div>
          <div class="detail_sub4">支持本地人脸特征库存储不少于1万条,</div>
          <div class="detail_sub4">支持人证比对，人证核验速度&lt;2s</div>
        </div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/rc13/img5.png"
        class="detail_img"
        ref="targetElement4"
      />
      <div :class="['detail_text5', show4 ? 'gradient_left' : '']">
        <div class="detail_title">开放接口</div>
        <div class="detail_title">支持应用扩展</div>
        <div class="detail_sub3">可接入门禁通道、考勤系统等第三方系统</div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/rc13/img6.png"
        class="detail_img"
        ref="targetElement5"
      />
      <div :class="['detail_text6', show5 ? 'gradient_left' : '']">桌面式</div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/rc13/img7.png"
        class="detail_img"
        ref="targetElement6"
      />
      <div :class="['detail_text6', show6 ? 'gradient_left' : '']">立柱式</div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/rc13/img8.png"
        class="detail_img"
        ref="targetElement7"
      />
      <div :class="['detail_text6', show7 ? 'gradient_left' : '']">闸机式</div>
    </div>

    <div class="parameter">
      <div class="parameter_title">产品参数</div>
      <div v-for="(item, index) in argumentsList" :key="index">
        <div class="parameter_tab">{{ item.name }}</div>
        <div
          class="parameter_item"
          v-for="citem in item.list"
          :key="citem.name"
        >
          <div class="parameter_item_title">{{ citem.name }}</div>
          <div class="parameter_item_content">{{ citem.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
export default {
  data() {
    return {
      argumentsList: [
        {
          name: "常规参数",
          list: [
            {
              name: "产品名称与型号",
              content: "证码通人脸识别终端RC13",
            },
            {
              name: "系统",
              content: "Android 7.1及以上 存储2G RAM+8G ROM",
            },
            {
              name: "网络",
              content:
                "4G全网通（移动、联通、电信）Wi-Fi 2.4GHz IEEE 802.11 b/g/n; 以太网",
            },
            {
              name: "尺寸",
              content: "133mm（长）*120.2mm（宽）*361mm（高）",
            },
            {
              name: "安装方式",
              content: "立式支架、闸机通道、壁挂、桌面支架（选配）",
            },
          ],
        },
        {
          name: "摄像头",
          list: [
            {
              name: "分辨率",
              content: "200万像素，1920*1080",
            },
            {
              name: "类型",
              content: "双目宽动态摄像头，IR、RGB双重采集人脸图像",
            },
            {
              name: "人脸识别",
              content: "支持活体人脸识别，视频、照片防骗",
            },
            {
              name: "补光灯",
              content: "红外双补光灯",
            },
          ],
        },
        {
          name: "屏幕",
          list: [
            {
              name: "规格",
              content: "8寸IPS高清触摸屏(抗UV)",
            },
            {
              name: "分辨率",
              content: "800*1280",
            },
            {
              name: "触摸",
              content:
                "支持多点触摸，⽀持湿⼿、⼿套操作，采⽤防油渍指纹涂层(选配）",
            },
          ],
        },
        {
          name: "扫码模块",
          list: [
            {
              name: "图像传感器",
              content: "640×480 CMOS",
            },
            {
              name: "支持码型",
              content:
                "一维码：EAN13、EAN8、UPC-A、UPC-E0、UPC-E1、Code128、Code39、Code93、CodaBar 、Interleaved 2 of 5、Industrial 25、Matrix 2 of 5、Code11、 MSI Plessey 、 RSS-14、限定式 RSS、扩展式 RSS 二维码：QR Code、PDF417、DataMatrix（ECC200） ",
            },
            {
              name: "灵敏度",
              content: "倾斜±60°，偏转±60°，旋转 360°",
            },
          ],
        },
        {
          name: "读证模块",
          list: [
            {
              name: "读卡类型",
              content:
                "ISO/IEC 14443A、14443B、Mifare等非接触卡TypeA卡/TypeB卡及电子标签，包含身份证、港澳台居民居住证、外国人永久居留证、公交卡、市政卡、老年卡、学生卡、护照、elD电子证件等。支持SAM_II，SAM_III",
            },
            {
              name: "识别距离",
              content: "0-3cm",
            },
            {
              name: "读卡时间",
              content: "≤2秒",
            },
          ],
        },
        {
          name: "接口",
          list: [
            {
              name: "电源",
              content: "1路DC 12V 2A 电源",
            },
            {
              name: "以太网",
              content: "1路RJ45",
            },
            {
              name: "RS232",
              content: "1路串口通讯接口",
            },
            {
              name: "USB",
              content: "2路USB 标准A口 ",
            },
          ],
        },
        {
          name: "工作温度/湿度",
          list: [
            {
              name: "工作温度/存放温度",
              content: "0℃～50℃/-40℃～70℃",
            },
            {
              name: "工作湿度及存放湿度",
              content: "5%～95%非凝结",
            },
          ],
        },
        {
          name: "装箱清单",
          list: [
            {
              name: "",
              content: "主机*1、  电源适配器*1、  Wi-Fi天线*1、  4G天线*2",
            },
          ],
        },
      ],
      show1: false, //是否显示
      show2: false, //是否显示
      show3: false, //是否显示
      show4: false, //是否显示
      show5: false, //是否显示
      show6: false, //是否显示
      show7: false, //是否显示
    };
  },
  directives: {
    ScrollTo: VueScrollTo.directive,
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll2);
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll2);
  },
  methods: {
    // 获取页面滚动距离
    handleScroll2() {
      const element = this.$refs.targetElement;
      const element2 = this.$refs.targetElement2;
      const element3 = this.$refs.targetElement3;
      const element4 = this.$refs.targetElement4;
      const element5 = this.$refs.targetElement5;
      const element6 = this.$refs.targetElement6;
      const element7 = this.$refs.targetElement7;


      const rect = element.getBoundingClientRect();
      const rect2 = element2.getBoundingClientRect();
      const rect3 = element3.getBoundingClientRect();
      const rect4 = element4.getBoundingClientRect();
      const rect5 = element5.getBoundingClientRect();
      const rect6 = element6.getBoundingClientRect();
      const rect7 = element7.getBoundingClientRect();

      if (rect.top <= window.innerHeight && rect.bottom <= 1500) {
        // 元素在可视区域内
        if (!this.show1) {
          this.show1 = true;
        }
      }

      if (rect2.top <= window.innerHeight && rect2.bottom <= 1500) {
        if (!this.show2) {
          this.show2 = true;
        }
      }
      if (rect3.top <= window.innerHeight && rect3.bottom <= 1500) {
        if (!this.show3) {
          this.show3 = true;
        }
      }
      if (rect4.top <= window.innerHeight && rect4.bottom <= 1500) {
        if (!this.show4) {
          this.show4 = true;
        }
      }
      if (rect5.top <= window.innerHeight && rect5.bottom <= 1500) {
        if (!this.show5) {
          this.show5 = true;
        }
      }
      if (rect6.top <= window.innerHeight && rect6.bottom <= 1500) {
        if (!this.show6) {
          this.show6 = true;
        }
      }
      if (rect7.top <= window.innerHeight && rect7.bottom <= 1500) {
        if (!this.show7) {
          this.show7 = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.detail {
  width: 100%;
  height: 67.5rem;
  position: relative;
}
.detail_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.detail_text {
  color: white;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 23.3125rem;
  right: 22.3125rem;
  opacity: 0;
}
.detail_title {
  font-size: 5rem;
  font-weight: bold;
  text-align: left;
}
.detail_sub {
  font-size: 2rem;
  text-align: left;
  margin-top: 3.75rem;
}
.detail_sub3 {
  font-size: 1.375rem;
  text-align: left;
  margin-top:1.25rem;
}
.detail_sub5 {
  font-size: 1.375rem;
  text-align: center;
  margin-top: 1.25rem;
  width: 56.5rem;
}
.detail_sub2 {
  font-size: 1.375rem;
  text-align: left;
  margin-top: 1.25rem;
  width: 41.375rem;
}
.detail_text2 {
  position: absolute;
  top: 10.3125rem;
  left: 9.125rem;
  opacity: 0;
  color: #000000ff;
}
.detail_tab {
  display: flex;
  width: 41.375rem;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 13.125rem;
}
.detail_tab div {
  flex: 0 0 50%;
  text-align: left;
  box-sizing: border-box;
  padding-left: 1.5625rem;
  font-size: 1.25rem;
  color: #333333ff;
  position: relative;
  margin-bottom: 2.5rem;
}
.detail_tab div::after {
  content: "";
  background: #333333ff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  width: 0.875rem;
  height: 0.875rem;
}
.detail_text3 {
  position: absolute;
  color: white;
  top: 7.5rem;
  opacity: 0;
  left: 31.75rem;
}
.detail2 {
  width: 100%;
  height: 81.5625rem;
  position: relative;
}
.detail_title2 {
  font-size: 5rem;
  font-weight: bold;
  text-align: center;
}
.detail_sub3 {
  width: 58.75rem;
  font-size: 1.375rem;
  margin-top: 1.25rem;
}
.detail_text4 {
  position: absolute;
  top: 9.125rem;
  left: 9.125rem;
  opacity: 0;
  color: #000000ff;
}
.detail_orther {
  margin-top: 1.25rem;
}
.detail_sub4 {
  font-size: 1.375rem;
  color: #000000ff;
  text-align: left;
}
.detail_text5 {
  position: absolute;
  left: 9.125rem;
  top: 25.4375rem;
  opacity: 0;
  color: white;
}
.detail_text6 {
  position: absolute;
  left: 9.125rem;
  opacity: 0;
  top: 11.875rem;
  color: #000000ff;
  font-size: 3.75rem;
  font-weight: bold;
}

/* 产品参数 */
.parameter_title {
  width: 100%;
  line-height: 13.75rem;
  height: 13.75rem;
  padding-left: 9.125rem;
  box-sizing: border-box;
  font-size: 3.75rem;
  text-align: left;
  color: #000000ff;
  font-weight: bold;
}
.parameter_tab {
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  padding-left: 8.75rem;
  box-sizing: border-box;
  background: #fafafa;
  text-align: left;
  font-size: 1.75rem;
  color: #000000ff;
}
.parameter_item {
  width: 101.75rem;
  margin: 0 auto;
  border-bottom: 0.0625rem solid #f5f5f5ff;
  display: flex;
  justify-content: flex-start;
  min-height: 4.5rem;
  align-items: center;
}
.parameter_item_title {
  width: 29.625rem;
  font-size: 1.25rem;
  color: #797979ff;
  text-align: left;
}
.parameter_item_content {
  max-width: 71.75rem;
  font-size: 1.25rem;
  color: #333333ff;
  text-align: left;
}
</style>
