<template>
  <div class="home">
    <div class="SwiperBox">
      <!-- 文字 -->
      <div
        v-for="(item, index) in imgList"
        :key="item.title"
        class="SwiperBox_title"
      >
        <transition>
          <div v-if="isshow && ShowTitle == index">
            <div class="title">{{ item.title }}</div>
            <div class="tips">{{ item.tips }}</div>
          </div>
        </transition>
      </div>
      <!-- 图片 -->
      <img
        :class="['imgCss', ShowImg == index ? 'ShowCss' : '']"
        :src="item.imgUrl"
        v-for="(item, index) in imgList"
        :key="item.imgUrl"
      />
      <!-- 下方指示点容器 -->
      <div class="instBox">
        <div
          v-for="(item, index) in imgList.length"
          :key="index"
          @click="switchRotation(index)"
          :class="['inst', ShowImg == index ? 'instActv' : '']"
        ></div>
      </div>
    </div>

    <!-- 产品 -->
    <div class="content">
      <div class="introduction">
        <div
          v-for="item in productList"
          :key="item.title"
          class="introduction_single"
        >
          <img
            src="@/assets/productback.png"
            class="introduction_single_back"
          />
          <div class="introduction_single_title">{{ item.title }}</div>
          <div class="introduction_single_tips">{{ item.tips }}</div>
          <div class="introduction_single_more" @click="skip(item.index)">
            <div>查看更多产品</div>
            <img
              src="@/assets/productright.png"
              class="introduction_single_right"
            />
          </div>
          <img :src="item.img" class="introduction_single_img" />
        </div>
      </div>
    </div>

    <!-- 行业解决方案 -->
    <div class="industry">
      <div class="industry_title">行业解决方案</div>
      <div class="industry_tips">一对一精准定制，赋能价值</div>
      <!-- 轮播图 -->
      <div class="industry_swiper">
        <el-carousel :interval="3000" type="card" height="762px" arrow="never">
          <el-carousel-item v-for="item in industryList" :key="item.title">
            <div class="industry_item">
              <img :src="item.img" class="industry_img" />
              <div class="industry_swiper_title">{{ item.title }}</div>
              <div class="industry_swiper_tips">{{ item.tips }}</div>
              <div
                class="industry_swiper_more"
                @click="jumpprogramme(item.url)"
              >
                <div>了解更多</div>
                <img
                  src="@/assets/industryright.png"
                  class="industry_swiper_moreicon"
                />
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <!-- 合作伙伴 -->
    <div class="partner">
      <div class="industry_title">合作伙伴 智创未来</div>
      <div class="industry_tips">
        精研多场景解决方案，为用户带来更优的服务与价值
      </div>
      <vue-seamless-scroll
        :data="partnersList"
        :class-option="optionLeft"
        class="horserace"
      >
        <ul class="horserace_item_list">
          <li v-for="item in partnersList" :key="item" class="horserace_item">
            <img :src="item" class="horserace_item" />
          </li>
        </ul>
        <!-- <ul class="horserace_item_list">
          <li v-for="item in partnersList2" :key="item" class="horserace_item">
            <img :src="item" class="horserace_item" />
          </li>
        </ul> -->
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
import carousel1 from "../assets/carousel1.png";
import carousel2 from "../assets/carousel2.png";
import carousel3 from "../assets/carousel3.png";

import p1 from "../assets/p1.png";
import p2 from "../assets/p2.png";
import p3 from "../assets/p3.png";
import p4 from "../assets/p4.png";

import h1 from "../assets/h1.png";
import h2 from "../assets/h2.png";
import h3 from "../assets/h3.png";
import h4 from "../assets/h4.png";
import h5 from "../assets/h5.png";
import h6 from "../assets/h6.png";
import h8 from "../assets/h8.png";

import parner1 from "../assets/partners/1.png";
import parner2 from "../assets/partners/2.png";
import parner3 from "../assets/partners/3.png";
import parner4 from "../assets/partners/4.png";
import parner5 from "../assets/partners/5.png";
import parner6 from "../assets/partners/6.png";
import parner7 from "../assets/partners/7.png";
import parner8 from "../assets/partners/8.png";
import parner9 from "../assets/partners/9.png";
import parner10 from "../assets/partners/10.png";
import parner11 from "../assets/partners/11.png";
import parner12 from "../assets/partners/12.png";
import parner13 from "../assets/partners/13.png";
import parner14 from "../assets/partners/14.png";
import parner15 from "../assets/partners/15.png";
import parner16 from "../assets/partners/16.png";
import parner17 from "../assets/partners/17.png";
import parner18 from "../assets/partners/18.png";
import parner19 from "../assets/partners/19.png";
import parner20 from "../assets/partners/20.png";

import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "HelloWorld",
  data() {
    return {
      imgList: [
        {
          title: "自研算法 高效赋能",
          tips: "AI算力 / 人脸算力 / 边缘算力服务由广入深",
          imgUrl: carousel1,
        },
        {
          title: "实名制产品方案广泛应用",
          tips: "助力便民出行 / 电子政务 / 无纸化办公 / 自助酒旅 / 快速医疗 / 无人售卡 / 门禁控制等场景",
          imgUrl: carousel2,
        },
        {
          title: "开放平台",
          tips: "资源整合充分利旧，共邀开发者创造无限可能",
          imgUrl: carousel3,
        },
      ],
      productList: [
        {
          title: "智能通行",
          tips: "门禁控制，人员管理",
          index: 1,
          img: p1,
        },
        {
          title: "自助服务",
          tips: "自助收银，无人售卡",
          index: 2,
          img: p2,
        },
        {
          title: "实名核验",
          tips: "身份核验，信息加密",
          index: 3,
          img: p3,
        },
        {
          title: "数据分析",
          tips: "节点分析，强大算力",
          index: 4,
          img: p4,
        },
      ],
      industryList: [
        {
          title: "智慧园区",
          tips: "多维度构建丰富、高效的园区服务",
          url: "/park",
          img: h1,
          index: 1,
        },
        {
          title: "智慧校园",
          tips: "孩、家、校一站式掌握实时动态",
          url: "/campus",
          img: h2,
          index: 2,
        },
        {
          title: "智慧工地",
          tips: "智能化落实工地、工人安全管控",
          url: "/construction",
          img: h6,
          index: 3,
        },
        {
          title: "智慧厅店",
          tips: "数字化管控营业厅门店",
          url: "/construction",
          img: h8,
          index: 4,
        },
        {
          title: "新零售",
          tips: "多元化打造全世界零售方式",
          url: "/construction",
          img: h5,
          index: 5,
        },
        {
          title: "智慧公租房",
          tips: "提供无忧虑全方位租房服务",
          url: "/public",
          img: h3,
          index: 6,
        },
        {
          title: "智慧交通",
          tips: "交通故障自动化指挥调度",
          url: "/traffic",
          img: h4,
          index: 7,
        },
      ],
      partnersList: [
        parner1,
        parner2,
        parner3,
        parner4,
        parner5,
        parner11,
        parner12,
        parner13,
        parner14,
        parner15,
        parner6,
        parner7,
        parner8,
        parner9,
        parner10,
        parner16,
        parner17,
        parner18,
        parner19,
        parner20,
      ],
      partnersList2: [
        parner6,
        parner7,
        parner8,
        parner9,
        parner10,
        parner16,
        parner17,
        parner18,
        parner19,
        parner20,
      ],
      currentIndex: 0, // 初始时中间的卡片索引
      ShowImg: 0, // 表示当前显示的图片
      ShowTitle: 0,
      isshow: true,
      flag: true, // 用来节流防止重复点击
      start: null, // 自动执行下一张定时器
    };
  },
  components: { vueSeamlessScroll },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: this.partnersList.length,
      };
    },
  },
  mounted() {
    this.setTimeoFun();
  },
  methods: {
    // 这里定义一个鼠标移入移出事件，鼠标悬停时停止自动轮播，鼠标移出则重新执行自动轮播
    // MouseFun(type) {
    //   // 停止定时器            // 重新执行定时器
    //   type == "移入" ? clearTimeout(this.start) : this.setTimeoFun();
    // },
    setTimeoFun() {
      this.start = setInterval(() => {
        this.NextFun();
      }, 5000);
    },
    // 下一张
    NextFun() {
      this.isshow = false;
      if (this.ShowImg !== this.imgList.length - 1) {
        this.ShowImg++;

        setTimeout(() => {
          this.ShowTitle++;
          this.isshow = true;
        }, 1000);
      } else {
        this.ShowImg = 0;
        setTimeout(() => {
          this.ShowTitle = 0;
          this.isshow = true;
        }, 1000);
      }
    },

    // 切换轮播
    switchRotation(index) {
      this.isshow = false;
      this.ShowImg = index;
      this.ShowTitle = -1;
      setTimeout(() => {
        this.ShowTitle = index;
        this.isshow = true;
      }, 1000);
    },

    // 跳转到产品分类
    skip(index) {
      this.$router.replace({
        path: "/product",
        query: {
          index: index,
        },
      });
    },

    // 跳到解决方案
    jumpprogramme(url) {
      this.$router.replace({
        path: url,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* 图片容器样式 */
.SwiperBox {
  position: relative;
  width: 100%;
  height: 59.375rem;
  cursor: pointer;
}
/* 图片默认样式 */
.imgCss {
  position: absolute;
  left: 0rem;
  top: 0rem;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  transition: 1.5s; /* 淡入淡出过渡时间 */
}
/* 图片选中样式(继承上方默认样式) */
.ShowCss {
  opacity: 1;
}
/* 下方指示器盒子 */
.instBox {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2.5rem;
  display: flex;
}
/* 小圆点 */
.inst {
  width: 3rem;
  height: 0.25rem;
  background: #ffffff;
  border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
  opacity: 0.3;
  margin-right: 1.125rem;
  cursor: pointer;
}
.inst:last-child {
  margin-right: 0rem;
}
.instActv {
  opacity: 1;
}
.SwiperBox_header {
  color: white;
}

.v-enter-active {
  animation: myAni 1s;
}

.v-leave-active {
  animation: myAni 0.2s reverse;
}

@keyframes myAni {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0rem);
  }
}
.SwiperBox_title {
  position: absolute;
  top: 21.4375rem;
  left: 12.1875rem;
}
.title {
  font-size: 3.75rem;
  font-weight: 500;
  color: #ffffff;
  text-align: left;
}
.tips {
  font-size: 1.625rem;
  font-weight: 300;
  color: #ffffff;
  margin-top: 2.625rem;
  width: 44.1875rem;
  text-align: left;
}
.content {
  width: 100%;
}
.introduction {
  width: 100%;
  padding: 1.875rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.introduction_single {
  width: 57.1875rem;
  height: 45.625rem;
  padding-top: 3.125rem;
  box-sizing: border-box;
  margin-bottom: 1.875rem;
  position: relative;
}
.introduction_single_back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.introduction_single_title {
  font-size: 2.375rem;
  color: #000000ff;
  font-weight: bold;
}
.introduction_single_tips {
  font-size: 1.5rem;
  color: #333333ff;
  margin: 0.8125rem 0 0.75rem;
}
.introduction_single_more {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #448ef7ff;
  font-size: 1.25rem;
  cursor: pointer;
}
.introduction_single_right {
  width: 0.5625rem;
  height: 0.9375rem;
  margin-left: 0.9375rem;
}
.industry {
  margin-top: 5.625rem;
}
.industry_title {
  font-size: 3.125rem;
  color: #030303ff;
  font-weight: bold;
}
.industry_tips {
  font-size: 1.75rem;
  color: #333333ff;
  margin-top: 0.875rem;
}
.industry_swiper {
  margin: 6.25rem 0 10rem;
}
.industry_item {
  position: relative;
  margin: 0 auto;
  text-align: center;
  width: 31.875rem;
  height: 47.625rem;
  padding-top: 4.25rem;
  box-sizing: border-box;
}
.industry_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.is-active .industry_item {
  width: 36.25rem !important;
}
.industry_swiper_title {
  font-size: 2.9375rem;
  color: white;
  font-weight: 500;
}
.industry_swiper_tips {
  font-size: 1.5625rem;
  color: white;
  margin: 1rem 0 1.25rem;
  font-weight: 500;
}
.industry_swiper_more {
  display: flex;
  align-items: center;
  font-size: 1.4375rem;
  justify-content: center;
  color: rgba(250, 250, 250, 0.6);
}
.industry_swiper_moreicon {
  width: 0.375rem;
  height: 0.6875rem;
  margin-left: 0.625rem;
}
.horserace {
  width: 101.875rem;
  margin: 3.125rem auto 10rem;
  height: 17.5rem;
  overflow: hidden;
}
.horserace_item_list {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}
.horserace_item {
  width: 18.875rem;
  height: 8.75rem;
}
.introduction_single_img {
  width: 55rem;
  height: 32.5rem;
}
::v-deep .el-carousel__indicators {
  position: absolute !important;
  left: 44%;
}
::v-deep .el-carousel__indicators button {
  width: 3rem;
  height: 0.25rem;
  border-radius: 0.625rem 0.625rem 0.625rem 0.625rem;
  background: #ffffff;
  opacity: 0.3;
}
::v-deep .el-carousel__indicators .is-active button {
  background: white;
}
::v-deep .el-carousel__mask {
  background: rgb(0, 0, 0, 0);
}
</style>
