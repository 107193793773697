<template>
  <div>
    <img src="@/assets/mobile/solution/img7.jpg" class="banner" />
    <span class="park_title">智慧交通解决方案</span>
    <div class="content">
      <div class="title">方案概述</div>
      <div class="sub">
        智慧交通解决方案是为道路交通事故自动化设障的软硬件智能系统，系统由交通故障指挥平台、道路监控云台和交通设障机器人三部分组成，采用4G通讯网络、高精度定位技术、远程监控技术和智能控制技术，通过监控云台报送事故点信息到交通故障指挥平台，平台调度指挥设障机器人为事故现场自动化设置围栏，保障司乘人员和车辆安全。
      </div>
    </div>

    <img src="@/assets/mobile/solution/img8.jpg" class="blueprint" />
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.banner {
  width: 100%;
  height: 26rem;
}
.park_title {
  position: absolute;
  top: 12.125rem;
  left: 0;
  right: 0;
  font-size: 1.6875rem;
  color: #ffffff;
}
.title {
  font-size: 1.4375rem;
  color: #333333ff;
  text-align: left;
  font-weight: bold;
}
.content {
  width: 100%;
  padding: 2.625rem 1.9375rem;
  box-sizing: border-box;
}
.sub {
  width: 20rem;
  margin-top: 1.3125rem;
  font-size: 0.9375rem;
  text-align: left;
}

.blueprint {
  width: 100%;
  height: 22.125rem;
}
</style>