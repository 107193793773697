<template>
  <div>
    <div class="banner">
      <van-swipe
        class="my-swipe"
        :autoplay="5000"
        indicator-color="white"
        @change="swiperChange"
      >
        <van-swipe-item
          v-for="(item, index) in banner"
          :key="index"
          class="swipe_item"
        >
          <img :src="item.img" class="banner_img" />
          <transition>
            <div class="banner_text" v-if="isshow">
              <div class="banner_title">{{ item.text }}</div>
              <div class="banner_tips">{{ item.tips }}</div>
            </div>
          </transition>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="product">
      <div class="product_item" v-for="item in productList" :key="item.text">
        <div class="product_text">{{ item.text }}</div>
        <div class="product_tips">{{ item.tips }}</div>
        <div class="product_more" @click="productMore(item.index)">
          <div>查看更多产品</div>
          <img src="@/assets/mobile/right.png" class="product_more_icon" />
        </div>
        <img :src="item.img" class="product_img" />
      </div>
    </div>
    <div class="solution">
      <div class="solution_title">行业解决方案</div>
      <div class="solution_tips">一对一精准定制，赋能价值</div>
      <div v-for="item in solveList" :key="item.text" class="solve">
        <img :src="item.img" class="solve_img" />
        <div class="solve_title">{{ item.text }}</div>
        <div class="solve_tips">{{ item.tips }}</div>
        <div class="solve_more" @click="naviMor(item.url)">
          <div>了解更多</div>
          <img src="@/assets/mobile/whiteright.png" class="solve_more_icon" />
        </div>
      </div>
    </div>
    <div class="solution">
      <div class="solution_title">合作伙伴 智创未来</div>
      <div class="solution_tips">精研多场景解决方案</div>
      <div class="solution_tips">为用户带来更优的服务与价值</div>
      <div class="partner">
        <img
          :src="item"
          v-for="(item, index) in partner"
          :key="index"
          class="partner_item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import banner1 from "../assets/mobile/banner1.jpg";
import banner2 from "../assets/mobile/banner2.jpg";
import banner3 from "../assets/mobile/banner3.jpg";

import product1 from "../assets/mobile/product1.png";
import product2 from "../assets/mobile/product2.png";
import product3 from "../assets/mobile/product3.png";
import product4 from "../assets/mobile/product4.png";

import solve1 from "../assets/mobile/solve1.jpg";
import solve2 from "../assets/mobile/solve2.jpg";
import solve3 from "../assets/mobile/solve3.jpg";
import solve4 from "../assets/mobile/solve4.jpg";
import solve5 from "../assets/mobile/solve5.jpg";
import solve6 from "../assets/mobile/solve6.jpg";
import solve7 from "../assets/mobile/solve7.jpg";

import cooperate1 from "../assets/mobile/1.jpg";
import cooperate2 from "../assets/mobile/2.jpg";
import cooperate3 from "../assets/mobile/3.jpg";
import cooperate4 from "../assets/mobile/4.jpg";
import cooperate5 from "../assets/mobile/5.jpg";
import cooperate6 from "../assets/mobile/6.jpg";
import cooperate7 from "../assets/mobile/7.jpg";
import cooperate8 from "../assets/mobile/8.jpg";
import cooperate9 from "../assets/mobile/9.jpg";
import cooperate10 from "../assets/mobile/10.jpg";
import cooperate11 from "../assets/mobile/11.jpg";
import cooperate12 from "../assets/mobile/12.jpg";
import cooperate13 from "../assets/mobile/13.jpg";
import cooperate14 from "../assets/mobile/14.jpg";
import cooperate15 from "../assets/mobile/15.jpg";
import cooperate16 from "../assets/mobile/16.jpg";
import cooperate17 from "../assets/mobile/17.jpg";
import cooperate18 from "../assets/mobile/18.jpg";
import cooperate19 from "../assets/mobile/19.jpg";
import cooperate20 from "../assets/mobile/20.jpg";
export default {
  data() {
    return {
      isshow: true,
      banner: [
        {
          img: banner1,
          text: "自研算法 高效赋能",
          tips: "AI算力 / 人脸算力 / 边缘算力服务由广入深",
        },
        {
          img: banner2,
          text: "实名制产品方案广泛应用",
          tips: "助力便民出行 / 电子政务 / 无纸化办公 / 自助酒旅 / 快速医疗 / 无人售卡 / 门禁控制等场景",
        },
        {
          img: banner3,
          text: "开发平台",
          tips: "资源整合充分利旧，共邀开发者创造无限可能",
        },
      ],
      productList: [
        {
          img: product1,
          text: "智能通行",
          tips: "门禁控制，人员管理",
          index: 1,
        },
        {
          img: product2,
          text: "自助服务",
          tips: "自助收银，无人售卡",
          index: 2,
        },
        {
          img: product3,
          text: "实名核验",
          tips: "身份核验，信息加密",
          index: 3,
        },
        {
          img: product4,
          text: "数据分析",
          tips: "节点分析，强大算力",
          index: 4,
        },
      ],
      solveList: [
        {
          img: solve1,
          text: "智慧园区",
          url: "park",
          tips: "多维度构建丰富、高效的园区服务",
        },
        {
          img: solve2,
          text: "智慧校园",
          url: "campus",
          tips: "孩、家、校一站式掌握实时动态",
        },
        {
          img: solve3,
          text: "智慧工地",
          url: "campus",
          tips: "智能化落实工地、工人安全管控",
        },
        {
          img: solve4,
          text: "智慧交通",
          url: "traffic",
          tips: "交通故障自动化指挥调度",
        },
        {
          img: solve5,
          text: "智慧新零售",
          tips: "多元化打造全新零售方式",
        },
        {
          img: solve6,
          text: "智慧厅店",
          tips: "数字化管控营业厅、门店",
        },
        {
          img: solve7,
          text: "智慧公租房",
          url: "public",
          tips: "提供无忧虑全方位租房服务",
        },
      ],
      partner: [
        cooperate1,
        cooperate2,
        cooperate3,
        cooperate4,
        cooperate5,
        cooperate6,
        cooperate7,
        cooperate8,
        cooperate9,
        cooperate10,
        cooperate11,
        cooperate12,
        cooperate13,
        cooperate14,
        cooperate15,
        cooperate16,
        cooperate17,
        cooperate18,
        cooperate19,
        cooperate20,
      ],
    };
  },
  methods: {
    // 轮播移动
    swiperChange(index) {
      this.isshow = false;
      setTimeout(() => {
        this.isshow = true;
      }, 500);
    },

    // 查看更多产品
    productMore(index) {
      this.$router.replace({
        name: "mobileProduct",
        query: {
          index: index,
        },
      });
    },

    // 跳转智慧生活
    naviMor(url) {
      this.$router.replace({
        name: url,
      });
    },
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 33.125rem;
}
.my-swipe {
  width: 100%;
  height: 100%;
}
.swipe_item {
  width: 100%;
  height: 100%;
  position: relative;
}
.banner_img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.banner_text {
  margin-top: 5.3125rem;
  width: 100%;
  text-align: center;
  color: white;
}
.banner_title {
  font-size: 1.6875rem;
  font-weight: bold;
}
.banner_tips {
  font-size: .875rem;
  width:18.125rem;
  margin: 1.125rem auto 0;
}
.product_item {
  width: 22.4375rem;
  padding-top: 1.9375rem;
  box-sizing: border-box;
  height: 26rem;
  background: #f4f4f4;
  margin: 1rem auto 0;
}
.product_text {
  font-size: 1.5625rem;
  font-weight: bold;
}
.product_tips {
  margin: .125rem 0 .875rem;
  font-size: 1.0625rem;
}
.product_more {
  display: flex;
  align-items: center;
  color: #448ef7;
  font-size: .9375rem;
  justify-content: center;
}
.product_more_icon {
  width: .375rem;
  height: .6875rem;
  margin-left: .375rem;
}
.product_img {
  width: 20.8125rem;
  height: 16.125rem;
  margin: 1.3125rem auto 0;
}
.solution {
  margin-top: 3.875rem;
}
.solution_title {
  font-size: 1.5625rem;
  color: #000;
  margin-bottom: .75rem;
  font-weight: bold;
}
.solution_tips {
  font-size: 1.0625rem;
  color: #999999;
}
.solve {
  width: 22.4375rem;
  height: 29.25rem;
  margin: 1rem auto 0;
  position: relative;
  padding-top: 2.6875rem;
  box-sizing: border-box;
}
.solve_img {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: -1;
}
.solve_title {
  font-size: 1.6875rem;
  color: white;
  font-weight: bold;
}
.solve_tips {
  font-size: 1.0625rem;
  color: white;
  margin: .625rem 0 .75rem;
}
.solve_more {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .9375rem;
  color: #ffffff;
}
.solve_more_icon {
  width: .375rem;
  height: .6875rem;
  margin-left: .375rem;
}
.partner {
  width: 100%;
  margin-top: 2.625rem;
  padding: 0 1rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.partner_item {
  width: 7rem;
  height: 3.375rem;
  margin-bottom: 1.3125rem;
}
.partner::after {
  content: "";
  width: 7rem;
  height: 3.375rem;
}
.v-enter-active {
  animation: myAni 1s;
}

.v-leave-active {
  animation: myAni 0.2s reverse;
}

@keyframes myAni {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0px);
  }
}
</style>