<template>
  <div>
    <!-- <div class="detail"> -->
    <div class="detail_wrapper">
      <img
        src="@/assets/mobile/product/default.jpg"
        alt=""
        class="detail-img"
      />
      <div class="detail-text">方案正在上线中…</div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
.detail {
  width: 100%;
  height: 697px;
  position: relative;
}
.detail_wrapper {
  height: 43.5625rem;
  left: 0;
  width: 100%;
  margin-top: 13.6875rem;
}
.detail-img {
  width: 5.5625rem;
  height: 5.375rem;
}
.detail-text {
  margin-top: 0.75rem;
  color: #333333;
  font-size: 0.9375rem;
}
</style>

