<template>
  <div>
    <div class="detail_item">
      <img src="@/assets/mobile/product/stb2/1.jpg" class="detail_img" />
      <div :class="['detail_text', 'gradient_top']">
        <div class="detail_title">ST-B2双屏收银机</div>
        <div class="detail_tips">双屏收银,简单易用</div>
      </div>
      <div :class="['detail_ul', show1 ? 'gradient_bottom' : '']">
        <div class="detail_li" v-for="(item, index) in tab" :key="index">
          <img :src="item.img" class="detail_li_img" />
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement">
      <img src="@/assets/mobile/product/stb2/2.jpg" class="detail_img" />
      <div :class="['detail_text2', show2 ? 'gradient_left' : '']">
        <div class="detail_title2">双屏异显 场景交互</div>
        <div class="detail_tips2">主屏:<span>多点触摸,操作流畅</span></div>
        <div class="detail_tips2">
          副屏:<span>15.6寸高清工业级LCD屏还原真彩显示</span>
        </div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement2">
      <img src="@/assets/mobile/product/stb2/3.jpg" class="detail_img" />
      <div :class="['detail_text3', show3 ? 'gradient_top' : '']">
        <div class="detail_title3">桌面式</div>
        <div class="detail_tips3">精巧机身设计占用空间小</div>
        <div class="detail_tips3">主副屏幕角度可调最大30度,方便操作</div>
      </div>
    </div>
    <!-- 产品参数 -->
    <div class="parameter">
      <div class="parameter_title">产品参数</div>
      <div v-for="(item, index) in argumentsList" :key="index">
        <div class="parameter_tab">{{ item.name }}</div>
        <div
          class="parameter_item"
          v-for="citem in item.list"
          :key="citem.name"
        >
          <div class="parameter_item_name">{{ citem.name }}</div>
          <div class="parameter_item_anw">{{ citem.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
import icon1 from "../../assets/mobile/product/stb2/icon1.png";
import icon2 from "../../assets/mobile/product/stb2/icon2.png";
import icon3 from "../../assets/mobile/product/stb2/icon3.png";
import icon4 from "../../assets/mobile/product/stb2/icon4.png";
import icon5 from "../../assets/mobile/product/stb2/icon5.png";
import icon6 from "../../assets/mobile/product/stb2/icon6.png";
import icon7 from "../../assets/mobile/product/stb2/icon7.png";
import icon8 from "../../assets/mobile/product/stb2/icon8.png";
export default {
  data() {
    return {
      argumentsList: [
        {
          name: "常规参数",
          list: [
            {
              name: "产品名称与型号",
              content: "双屏收银机ST-B2",
            },
            {
              name: "系统",
              content: "Android7.1SrTi安全操作系统",
            },
            {
              name: "CPU",
              content: "ARMCortex-A17四核1.8GHz",
            },
            {
              name: "扩展存储",
              content: "支持MicroSD(TF)卡扩展(最高64G)",
            },
            {
              name: "内存",
              content: "RAM2G+ROM8GB(可选配2+16GB)",
            },
            {
              name: "以太网",
              content: "RJ45接口,10/100M",
            },
            {
              name: "Wi-Fi",
              content: "WiFi2.4GHz,支持IEEE802.11b/g/n",
            },
            {
              name: "USB",
              content: "底部USB*4,OTG*1,侧面USB*2",
            },
            {
              name: "扫码器(选配)",
              content:
                "内置扫码器,可识别各类主流一维条码及标准二维条\n(QRCode,Code128,EAN13,EAN-8,Matrix等)支持USB扩展",
            },
            {
              name: "打印机(选配)",
              content: "58热敏打印机",
            },
            {
              name: "电源",
              content: "输入:AC100~240V/2A\n输出:DC24V/2.71A",
            },
            {
              name: "其他接口",
              content: "钱箱接口:RJ11*1\n电子秤接口:DB9*1",
            },
            {
              name: "整机尺寸",
              content: "400mm(长)*400mm(宽)*370mm(高)",
            },
            {
              name: "整机重量",
              content: "7.5Kg(裸机,不含包装箱)",
            },
            {
              name: "工作环境",
              content: "工作温度:0°C~40 °C\n存储温度: - 20°C~60°C",
            },
          ],
        },
      ],
      tab: [
        {
          name: "扫码支付",
          img: icon1,
        },
        {
          name: "扫商品码",
          img: icon2,
        },
        {
          name: "凭条打印",
          img: icon3,
        },
        {
          name: "语音提示",
          img: icon4,
        },
        {
          name: "扩展存储",
          img: icon5,
        },
        {
          name: "双屏异显",
          img: icon6,
        },
        {
          name: "电子秤",
          img: icon7,
        },
        {
          name: "钱箱",
          img: icon8,
        },
      ],
      show1: false,
      show2: false,
      show3: false,
    };
  },

  created() {
    setTimeout(() => {
      this.show1 = true;
    }, 800);
  },
  directives: {
    ScrollTo: VueScrollTo.directive,
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    windowNaNpxoveEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 获取页面滚动距离
    handleScroll() {
      const element = this.$refs.targetElement;
      const element2 = this.$refs.targetElement2;
      const rect = element.getBoundingClientRect();
      const rect2 = element2.getBoundingClientRect();

      if (rect.top <= window.innerHeight && rect.bottom <= 1200) {
        if (!this.show2) {
          this.show2 = true;
        }
      }

      if (rect2.top <= window.innerHeight && rect2.bottom <= 1200) {
        if (!this.show3) {
          this.show3 = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.detail_item {
  width: 100%;
  height: 36.6875rem;
  position: relative;
}
.detail_item2 {
  width: 100%;
  height: 33.125rem;
  position: relative;
}
.detail_img {
  width: 100%;
  height: 100%;
}
.detail_text {
  top: 6.125rem;
  left: 0;
  width: 100%;
  opacity: 0;
  position: absolute;
}
.detail_title {
  font-size: 1.6875rem;
  color: white;
  font-weight: bold;
}
.detail_tips {
  font-size: 0.9375rem;
  color: white;
  margin-top: 0.9375rem;
}
.detail_ul {
  width: 100%;
  opacity: 0;
  padding: 0 2.625rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: absolute;
  bottom: 1.6875rem;
  left: 0;
}
.detail_li {
  display: flex;
  align-items: center;
  margin-top: 0.625rem;
  font-size: 0.75rem;
  color: white;
  flex: 0 0 33%;
}
.detail_ul::after {
  content: "";
  flex: 0 0 33%;
}
.detail_li_img {
  width: 1.5625rem;
  height: 1.5625rem;
  margin-right: 0.375rem;
}
.detail_text2 {
  top: 3.875rem;
  opacity: 0;
  left: 1.9375rem;
  position: absolute;
}
.detail_title2 {
  font-size: 1.6875rem;
  color: #000;
  font-weight: bold;
  text-align: left;
  margin-bottom: 1.1875rem;
}
.detail_tips2 {
  font-size: 0.9375rem;
  color: #333333;
  text-align: left;
  font-weight: bold;
  margin-bottom: 0.625rem;
}
.detail_tips2 span {
  font-weight: normal;
}
.detail_text3 {
  position: absolute;
  top: 3.875rem;
  opacity: 0;
  left: 0;
  width: 100%;
}
.detail_title3 {
  font-size: 1.6875rem;
  color: #000;
  font-weight: bold;
  margin-bottom: 1.25rem;
}
.detail_tips3 {
  font-size: 0.9375rem;
  color: #000;
}
.parameter {
  margin-top: 2.625rem;
}
.parameter_title {
  font-size: 1.4375rem;
  color: #000;
  font-weight: bold;
  padding-left: 1.3125rem;
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 1.3125rem;
}
.parameter_tab {
  width: 100%;
  height: 3.5625rem;
  background: #fafafa;
  text-align: left;
  font-size: 1.0625rem;
  padding-left: 1.3125rem;
  font-weight: bold;
  color: #000;
  box-sizing: border-box;
  line-height: 3.5625rem;
}
.parameter_item {
  width: 100%;
  padding: 1.3125rem 0;
  display: flex;
  align-items: center;
  border-bottom: 0.0625rem solid #dddddd;
}
.parameter_item_name {
  width: 4.5625rem;
  height: 100%;
  margin-right: 2.625rem;
  margin-left: 1.3125rem;
  box-sizing: border-box;
  text-align: left;
  font-size: 0.9375rem;
  color: #797979;
}
.parameter_item_anw {
  width: 13.75rem;
  word-wrap: break-word;
  white-space: pre-line;
  font-size: 0.9375rem;
  text-align: left;
  color: #333333;
}
</style>
