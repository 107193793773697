<template>
  <div>
    <div class="detail_item">
      <img src="@/assets/mobile/product/student/1.jpg" class="detail_img" />
      <div :class="['detail_text', 'gradient_top']">
        <div class="detail_title">智能电子学生证</div>
        <div class="detail_tips">让孩子可以随时随地联系家人</div>
      </div>
      <div :class="['detail_ul', show1 ? 'gradient_bottom' : '']">
        <div class="detail_li" v-for="(item, index) in tab" :key="index">
          <img :src="item.img" class="detail_li_img" />
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement">
      <img src="@/assets/mobile/product/student/2.jpg" class="detail_img" />
      <div :class="['detail_text2', show2 ? 'gradient_top' : '']">
        <div class="detail_title2">实时精准定位</div>
        <div class="detail_tips2">北斗+GPS+LBS+Wi-Fi四重实时定位</div>
        <div class="detail_tips2">实时查看孩子位置</div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement2">
      <img src="@/assets/mobile/product/student/3.jpg" class="detail_img" />
      <div :class="['detail_text3', show3 ? 'gradient_left' : '']">
        <div class="detail_title3">双向通话</div>
        <div class="detail_tips3">
          按键拨打小程序预置手机号码进行双向语音通话
        </div>
        <div class="detail_tips3">支持三大运营商4G全网通信，通话质量稳定</div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement3">
      <img src="@/assets/mobile/product/student/4.jpg" class="detail_img" />
      <div :class="['detail_text4', show4 ? 'gradient_top' : '']">
        <div class="detail_title4">SOS紧急呼叫</div>
        <div class="detail_tips4">一键SOS紧急呼叫</div>
        <div class="detail_tips4">自动向呼救对象推送实时位置信息</div>
      </div>
    </div>
    <!-- 产品参数 -->
    <div class="parameter">
      <div class="parameter_title">产品参数</div>
      <div v-for="(item, index) in argumentsList" :key="index">
        <div class="parameter_tab">{{ item.name }}</div>
        <div
          class="parameter_item"
          v-for="citem in item.list"
          :key="citem.name"
        >
          <div class="parameter_item_name">{{ citem.name }}</div>
          <div class="parameter_item_anw">{{ citem.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
import icon1 from "../../assets/mobile/product/student/icon1.png";
import icon2 from "../../assets/mobile/product/student/icon2.png";
import icon3 from "../../assets/mobile/product/student/icon3.png";
import icon4 from "../../assets/mobile/product/student/icon4.png";
import icon5 from "../../assets/mobile/product/student/icon5.png";
import icon6 from "../../assets/mobile/product/student/icon6.png";
export default {
  data() {
    return {
      argumentsList: [
        {
          name: "规格参数",
          list: [
            {
              name: "产品名称与型号",
              content: "智能电子学生证ESC-A1",
            },
            {
              name: "屏幕",
              content: "1.54寸触摸屏",
            },
            {
              name: "芯片",
              content: "紫光展锐8910S",
            },
            {
              name: "网络",
              content: "中国移动、中国联通、中国电信、中国广电",
            },
            {
              name: "尺寸",
              content: "107mm(长)*59mm(宽)*10mm(厚)",
            },
            {
              name: "重量",
              content: "64.8g",
            },

            {
              name: "定位",
              content: "GPS、北斗、Wi-Fi、LBS",
            },
            {
              name: "SIM",
              content: "NanoSIM",
            },
            {
              name: "电池",
              content: "1000mAH",
            },
            {
              name: "RFID",
              content: "2.4G/13.56M",
            },
            {
              name: "按键",
              content: "5个实体按键",
            },
            {
              name: "充电口",
              content: "防水Type-C",
            },
            {
              name: "语音",
              content: "麦克风&扬声器",
            },
            {
              name: "传感器",
              content: "重力加速度传感器",
            },
            {
              name: "ID卡",
              content: "便准ID尺寸证件照",
            },
            {
              name: "工作时间",
              content: "7-10天",
            },
            {
              name: "定制",
              content: "支持OEM,ODM定制",
            },
          ],
        },
      ],
      tab: [
        {
          name: "全网通4G",
          img: icon1,
        },
        {
          name: "精准定位",
          img: icon2,
        },
        {
          name: "双向通话",
          img: icon3,
        },
        {
          name: "高清彩屏",
          img: icon4,
        },
        {
          name: "多人监护",
          img: icon5,
        },
        {
          name: "SOS报警",
          img: icon6,
        },
      ],
      show1: false,
      show2: false,
      show3: false,
      show4: false,
    };
  },
  directives: {
    ScrollTo: VueScrollTo.directive,
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    windowNaNpxoveEventListener("scroll", this.handleScroll);
  },
  created() {
    setTimeout(() => {
      this.show1 = true;
    }, 1000);
  },

  methods: {
    // 获取页面滚动距离
    handleScroll() {
      const element = this.$refs.targetElement;
      const element2 = this.$refs.targetElement2;
      const element3 = this.$refs.targetElement3;

      const rect = element.getBoundingClientRect();
      const rect2 = element2.getBoundingClientRect();
      const rect3 = element3.getBoundingClientRect();

      if (rect.top <= window.innerHeight && rect.bottom <= 1200) {
        // 元素在可视区域内
        if (!this.show2) {
          this.show2 = true;
        }
      }

      if (rect2.top <= window.innerHeight && rect2.bottom <= 1200) {
        if (!this.show3) {
          this.show3 = true;
        }
      }
      if (rect3.top <= window.innerHeight && rect3.bottom <= 1200) {
        if (!this.show4) {
          this.show4 = true;
        }
      }
    },
  },
};
</script>

<style  scoped>
.detail_item {
  width: 100%;
  height: 36.6875rem;
  position: relative;
}
.detail_item2 {
  width: 100%;
  height: 33.125rem;
  position: relative;
}
.detail_img {
  width: 100%;
  height: 100%;
}
.detail_text {
  top: 6.125rem;
  left: 0;
  opacity: 0;
  width: 100%;
  position: absolute;
}
.detail_title {
  font-size: 1.6875rem;
  color: white;
  font-weight: bold;
}
.detail_tips {
  font-size: 0.9375rem;
  color: white;
  margin-top: 0.9375rem;
}
.detail_ul {
  width: 100%;
  padding: 0 2.625rem;
  opacity: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: absolute;
  bottom: 1.6875rem;
  left: 0;
}
.detail_li {
  display: flex;
  align-items: center;
  margin-top: 0.625rem;
  font-size: 0.75rem;
  color: white;
  flex: 0 0 33%;
}
.detail_ul::after {
  content: "";
  flex: 0 0 33%;
}
.detail_li_img {
  width: 1.5625rem;
  height: 1.5625rem;
  margin-right: 0.375rem;
}
.detail_text2 {
  top: 2.5625rem;
  left: 0;
  opacity: 0;
  width: 100%;
  position: absolute;
}
.detail_title2 {
  font-size: 1.6875rem;
  color: white;
  font-weight: bold;
  margin-bottom: 1.1875rem;
}
.detail_tips2 {
  font-size: 0.9375rem;
  color: white;
}
.detail_tips2 span {
  font-weight: normal;
}
.detail_text3 {
  position: absolute;
  top: 3.25rem;
  opacity: 0;
  left: 2.625rem;
}
.detail_title3 {
  font-size: 1.6875rem;
  color: #000;
  font-weight: bold;
  text-align: left;
  margin-bottom: 1.25rem;
}
.detail_tips3 {
  font-size: 0.9375rem;
  color: #000;
  text-align: left;
}
.detail_text4 {
  top: 3.875rem;
  opacity: 0;
  position: absolute;
  left: 0;
  width: 100%;
}
.detail_title4 {
  font-size: 1.6875rem;
  color: #000;
  font-weight: bold;
  margin-bottom: 1.1875rem;
}
.detail_tips4 {
  font-size: 0.9375rem;
  color: #000;
}
.parameter {
  margin-top: 2.625rem;
}
.parameter_title {
  font-size: 1.4375rem;
  color: #000;
  font-weight: bold;
  padding-left: 1.3125rem;
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 1.3125rem;
}
.parameter_tab {
  width: 100%;
  height: 3.5625rem;
  background: #fafafa;
  text-align: left;
  font-size: 1.0625rem;
  padding-left: 1.3125rem;
  font-weight: bold;
  color: #000;
  box-sizing: border-box;
  line-height: 3.5625rem;
}
.parameter_item {
  width: 100%;
  padding: 1.3125rem 0;
  display: flex;
  align-items: center;
  border-bottom: 0.0625rem solid #dddddd;
}
.parameter_item_name {
  width: 4.5625rem;
  height: 100%;
  margin-right: 2.625rem;
  margin-left: 1.3125rem;
  box-sizing: border-box;
  text-align: left;
  font-size: 0.9375rem;
  color: #797979;
}
.parameter_item_anw {
  width: 13.75rem;
  word-wrap: break-word;
  white-space: pre-line;
  font-size: 0.9375rem;
  text-align: left;
  color: #333333;
}
</style>