<template>
  <div>
    <div class="detail">
      <img src="@/assets/product/rc10/img1.png" class="detail_img" />
      <div :class="['detail_text', 'gradient_right']">
        <div class="detail_title">证码通RC10</div>
        <div class="detail_title">实名制Mini核验终端</div>
        <div class="detail_sub">内置安全芯片满足国密标准</div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/rc10/img2.png"
        class="detail_img"
        ref="targetElement"
      />
      <div :class="['detail_text2', show1 ? 'gradient_left' : '']">
        <div class="detail_title">多证可读</div>
        <div class="detail_sub2">
          支持读取ISO/IEC14443非接触卡TypeA卡/TypeB卡
        </div>

        <div class="detail_tab">
          <div>身份证</div>
          <div>港澳台居民居住证</div>
          <div>外国人永久居留证</div>
          <div>护照</div>
          <div>eID电子证件</div>
          <div>老年卡</div>
          <div>市政卡</div>
          <div>公交卡</div>
          <div>学生卡</div>
        </div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/rc10/img3.png"
        class="detail_img"
        ref="targetElement2"
      />
      <div :class="['detail_text3', show2 ? 'gradient_left' : '']">
        <div class="detail_title">便携可移动</div>
        <div class="detail_sub3">4G网络、内置电池超长待机、体积小巧</div>
      </div>
    </div>
    <div class="detail">
      <img
        src="@/assets/product/rc10/img4.png"
        class="detail_img"
        ref="targetElement3"
      />
      <div :class="['detail_text4', show3 ? 'gradient_left' : '']">
        <div class="detail_title">开放接口</div>
        <div class="detail_title">支持应用扩展</div>
        <div class="detail_sub3">可接入电子政务、运营商等第三方系统</div>
      </div>
    </div>

    <div class="parameter">
      <div class="parameter_title">产品参数</div>
      <div v-for="(item, index) in argumentsList" :key="index">
        <div class="parameter_tab">{{ item.name }}</div>
        <div
          class="parameter_item"
          v-for="citem in item.list"
          :key="citem.name"
        >
          <div class="parameter_item_title">{{ citem.name }}</div>
          <div class="parameter_item_content">{{ citem.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
export default {
  data() {
    return {
      argumentsList: [
        {
          name: "规格参数",
          list: [
            {
              name: "产品名称与型号",
              content: "证码通RC10",
            },
            {
              name: "系统",
              content: "ARM Cortex-R5处理器",
            },
            {
              name: " 内存",
              content: "16MBRAM+16MBROM",
            },
            {
              name: "网络",
              content: "支持移动、联通、电信4G/5G网络通讯",
            },
            {
              name: "屏幕",
              content: "2.4寸LCD液晶屏，分辨率240*320",
            },
            {
              name: "读卡",
              content: "兼容大小卡（SIM/USIM大/小卡）",
            },
            {
              name: "电池",
              content: "3.7V可充电锂电池，容量2000mAh",
            },
            {
              name: "喇叭",
              content: "1W",
            },
            {
              name: "接口",
              content: "Type-C兼容数据与充电口",
            },
            {
              name: "整机尺寸",
              content: "126mm（长）*74.6mm（宽）*12mm（厚）",
            },
            {
              name: "工作环境",
              content: "工作温度：0°C～50 °C ，存储温度： - 40°C～70°C",
            },
          ],
        },
      ],
      show1: false,
      show2: false,
      show3: false,
    };
  },

  directives: {
    ScrollTo: VueScrollTo.directive,
  },
  mounted() {
        this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll2);
  },

  methods: {
    // 获取页面滚动距离
    handleScroll() {
      const element = this.$refs.targetElement;
      const element2 = this.$refs.targetElement2;
      const element3 = this.$refs.targetElement3;

      if (element) {
        const rect = element.getBoundingClientRect();
        const rect2 = element2.getBoundingClientRect();
        const rect3 = element3.getBoundingClientRect();

        if (rect.top <= window.innerHeight && rect.bottom <= 1500) {
          // 元素在可视区域内
          if (!this.show1) {
            this.show1 = true;
          }
        }

        if (rect2.top <= window.innerHeight && rect2.bottom <= 1500) {
          if (!this.show2) {
            this.show2 = true;
          }
        }
        if (rect3.top <= window.innerHeight && rect3.bottom <= 1500) {
          if (!this.show3) {
            this.show3 = true;
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.detail {
  width: 100%;
  height: 67.5rem;
  position: relative;
}
.detail_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.detail_text {
  position: absolute;
  color: #000000ff;
  top: 31.75rem;
  right: 16.75rem;
  opacity: 0;
}
.detail_title {
  font-size: 5rem;
  font-weight: bold;
  text-align: left;
}
.detail_sub {
  font-size: 2rem;
  margin-top: 3.75rem;
  text-align: left;
}
.detail_text2 {
  position: absolute;
  color: #000000ff;
  top: 10.3125rem;
  left: 9.125rem;
  opacity: 0;
}
.detail_sub2 {
  font-size: 1.375rem;
  text-align: left;
  margin-top: 1.25rem;
  width: 41.375rem;
}
.detail_tab {
  display: flex;
  width: 41.375rem;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 13.125rem;
}
.detail_tab div {
  flex: 0 0 50%;
  text-align: left;
  box-sizing: border-box;
  padding-left: 1.5625rem;
  font-size: 1.25rem;
  color: #333333ff;
  position: relative;
  margin-bottom: 2.5rem;
}
.detail_tab div::after {
  content: "";
  background: #333333ff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  width: 0.875rem;
  height: 0.875rem;
}
.detail_text3 {
  position: absolute;
  left: 9.125rem;
  top: 28.9375rem;
  opacity: 0;
  color: #000000ff;
}
.detail_sub3 {
  font-size: 1.375rem;
  margin-top: 1.25rem;
  text-align: left;
}
.detail_text4 {
  position: absolute;
  color: white;
  left: 9.125rem;
  top: 25.4375rem;
  opacity: 0;
}
.parameter_title {
  width: 100%;
  line-height: 13.75rem;
  height: 13.75rem;
  padding-left: 9.125rem;
  box-sizing: border-box;
  font-size: 3.75rem;
  text-align: left;
  color: #000000ff;
  font-weight: bold;
}
.parameter_tab {
  width: 100%;
  height: 5rem;
  line-height: 5rem;
  padding-left: 8.75rem;
  box-sizing: border-box;
  background: #fafafa;
  text-align: left;
  font-size: 1.75rem;
  color: #000000ff;
}
.parameter_item {
  width: 101.75rem;
  margin: 0 auto;
  border-bottom: 0.0625rem solid #f5f5f5ff;
  display: flex;
  justify-content: flex-start;
  min-height: 4.5rem;
  align-items: center;
}
.parameter_item_title {
  width: 29.625rem;
  font-size: 1.25rem;
  color: #797979ff;
  text-align: left;
}
.parameter_item_content {
  max-width: 71.75rem;
  font-size: 1.25rem;
  color: #333333ff;
  text-align: left;
}
</style>