<template>
  <div>
    <!-- <Header /> -->
    <Header v-show="!(path === '/construction')"></Header>
    <router-view />
    <Underneath />

    <!-- 客服 -->
    <div :class="['customer', showDiglog ? 'top' : 'notop']">
      <img
        src="@/assets/customer.png"
        class="customer_icon"
        @click="showDiglog = true"
        v-if="!showDiglog"
      />
      <div class="customer_form" v-if="showDiglog">
        <div class="customer_form_head">
          <img
            src="@/assets/head.png"
            class="customer_form_headimg"
            @click="closeDialog()"
          />
          <div class="customer_form_headtext">填写信息，为您提供更好的服务</div>
          <div
            class="customer_form_headclose"
            @click="showDiglog = false"
          ></div>
        </div>
        <div class="customer_form_content">
          <el-form
            label-position="top"
            label-width="21.25rem"
            :model="formLabelAlign"
            ref="formLabel"
            :rules="rules"
          >
            <el-form-item label="公司名称:" prop="company">
              <el-input v-model="formLabelAlign.company"></el-input>
            </el-form-item>
            <el-form-item label="您的姓名:" prop="name">
              <el-input v-model="formLabelAlign.name"></el-input>
            </el-form-item>
            <el-form-item label="联系电话:" prop="phone">
              <el-input v-model="formLabelAlign.phone"></el-input>
            </el-form-item>
            <el-form-item label="您的诉求:" prop="ask">
              <el-input
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 4 }"
                v-model="formLabelAlign.ask"
                maxlength="100"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-form>
          <div class="customer_form_bto" @click="commitForm()">提交</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./header.vue";
import Underneath from "./underneath.vue";
import http from "../utils/http";
export default {
  data() {
    const valiatephone = (rule, value, callback) => {
      var res = /^[1][3,4,5,6.7,8,9][0-9]{9}$/;

      if (!value) {
        callback(new Error("手机号不能为空"));
      } else if (!res.test(value)) {
        callback(new Error("手机号格式错误，请重新填写"));
      } else {
        callback();
      }
    };

    return {
      path: "",
      showDiglog: true, //是否显示对话框
      formLabelAlign: {}, //表单
      rules: {
        company: [
          { required: true, message: "请填写公司名称", trigger: "blur" },
        ],
        name: [{ required: true, message: "请填写姓名", trigger: "blur" }],
        phone: [{ required: true, validator: valiatephone, trigger: "blur" }],
      },
    };
  },
  created() {
    this.getToken();
  },
  // 判断路由
  mounted() {
    this.path = this.$route.path;
    // console.log(this.$route.path)
  },
  watch: {
    $route(to, from) {
      this.path = to.path;
    },
  },
  components: {
    Header,
    Underneath,
  },
  methods: {
    commitForm() {
      this.$refs["formLabel"].validate((valid) => {
        if (valid) {
          var map = {};
          map = this.formLabelAlign;
          if (!this.formLabelAlign.ask) {
            map.ask = "无";
          }
          http.post("/mail/home_send", map).then((res) => {
            if (res.code == 200) {
              this.$message.success("提交成功");
              this.formLabelAlign = {};
              this.$refs["formLabel"].clearValidate();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    closeDialog() {
      this.showDiglog = false;
      this.$refs["formLabel"].clearValidate();
      this.formLabelAlign = {};
    },

    // 获取token
    getToken() {
      http.post("/admin/login", {
          username: "hhkf",
          password: "hhkf123",
        }).then((res) => {
          if (res.code == 200) {
            localStorage.setItem("token", res.data.token);
          }
        });
    },
  },
};
</script>

<style  scoped>
.customer {
  position: fixed;
  right: 3.125rem;
  z-index: 9999;
}
.top {
  top: 10%;
}
.notop {
  top: 60%;
}
.customer_icon {
  width: 3.75rem;
  height: 3.75rem;
  cursor: pointer;
}
.customer_form {
  position: absolute;
  right: 0;
  top: 4.625rem;
  width: 26.25rem;
  padding-bottom: 1.875rem;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0rem 0rem 1.25rem 0.0625rem rgba(0, 0, 0, 0.1);
}
.customer_form_head {
  width: 100%;
  padding-left: 1.25rem;
  box-sizing: border-box;
  height: 4.375rem;
  background: #448ef7ff;
  display: flex;
  align-items: center;
}
.customer_form_headimg {
  width: 2.3125rem;
  height: 2.4375rem;
}
.customer_form_headtext {
  font-size: 1.125rem;
  margin: 0 3.25rem 0 1.0625rem;
  color: white;
}
.customer_form_headclose {
  width: 1.375rem;
  height: 0.1875rem;
  background: white;
  cursor: pointer;
}
.customer_form_content {
  width: 21.25rem;
  margin: 1.1875rem auto 0;
}
::v-deep .el-form-item {
  margin-bottom: 0.625rem;
}
::v-deep .el-form--label-top .el-form-item__label {
  float: left;
  font-size: 1rem;
  color: #000000ff;
}
::v-deep .el-input__inner {
  height: 2.75rem;
}
.customer_form_bto {
  width: 100%;
  height: 3.375rem;
  margin-top: 1.875rem;
  background: #448ef7;
  text-align: center;
  line-height: 3.375rem;
  font-size: 1.125rem;
  color: white;
  cursor: pointer;
}
::v-deep .el-form-item__label {
  padding: 0;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
}
::v-deep .el-form-item__content {
  line-height: normal;
}
</style>