<template>
  <div>
    <div class="detail_item">
      <img src="@/assets/mobile/product/rc20/1.jpg" class="detail_img" />
      <div :class="['detail_text', 'gradient_bottom']">
        <div class="detail_title">证码通RC20</div>
        <div class="detail_title">证码核验手持移动终端</div>
        <div class="detail_tips">考勤管理 | 访客管理 | 电子门禁</div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement">
      <img src="@/assets/mobile/product/rc20/2.jpg" class="detail_img" />
      <div :class="['detail_text2', show1 ? 'gradient_left' : '']">
        <div class="detail_title2">多证可读</div>
        <div class="detail_tips2">
          支持读取ISO/IEC
          14443A、14443B、Mifare等非接触卡TypeA卡/TypeB卡及电子标签
        </div>
      </div>
      <div :class="['detail_ul', show1 ? 'gradient_left' : '']">
        <div class="detail_li">身份证</div>
        <div class="detail_li">港澳台居民居住证</div>
        <div class="detail_li">外国人永久居留证</div>
        <div class="detail_li">eID电子证件</div>
        <div class="detail_li">护照</div>
        <div class="detail_li">老年卡</div>
        <div class="detail_li">市政卡</div>
        <div class="detail_li">公交卡</div>
        <div class="detail_li">学生卡</div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement2">
      <img src="@/assets/mobile/product/rc20/3.jpg" class="detail_img" />
      <div :class="['detail_text3', show2 ? 'gradient_top' : '']">
        <div class="detail_title3">快速扫码 0.3s精准识别</div>
        <div class="detail_tips3">
          可识别一维码:UPC/EAN, Code128, Code39等 可识别二维码:QR Code、PDF417等
        </div>
      </div>
    </div>
    <div class="detail_item2" ref="targetElement3">
      <img src="@/assets/mobile/product/rc20/4.jpg" class="detail_img" />
      <div :class="['detail_text4', show3 ? 'gradient_top' : '']">
        <div class="detail_title4">开放接口,支持应用扩展</div>
        <div class="detail_tips4">可接入门禁通道、考勤系统等第三方系统</div>
      </div>
    </div>
    <!-- 产品参数 -->
    <div class="parameter">
      <div class="parameter_title">产品参数</div>
      <div v-for="(item, index) in parameter" :key="index">
        <div class="parameter_tab">{{ item.name }}</div>
        <div
          class="parameter_item"
          v-for="citem in item.list"
          :key="citem.name"
        >
          <div class="parameter_item_name">{{ citem.name }}</div>
          <div class="parameter_item_anw">{{ citem.content }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from "vue-scrollto";
export default {
  data() {
    return {
      parameter: [
        {
          name: "常规参数",
          list: [
            {
              name: "产品名称与型号",
              content: "证码通手持移动终端RC20",
            },
            {
              name: "CPU",
              content: "Qualcomm八核1.8G主频",
            },
            {
              name: "OS版本",
              content: "Android9.0",
            },
            {
              name: "存储容量",
              content: "RAM+ROM:2GB+16GB",
            },
            {
              name: "扩展存储",
              content: "MicroSD(TF)卡可扩展至128GB",
            },
            {
              name: "尺寸",
              content: "219mm(长)*111mm(宽)*21.5mm(高)",
            },
            {
              name: "定位导航",
              content:
                "集成GPS、北斗、GLONASS,内置天线,支持AGPS,精度10米以内",
            },
            {
              name: "电池容量",
              content: "6000mAh",
            },
            {
              name: "待机时长",
              content: "满电待机>8小时",
            },
            {
              name: "整机尺寸",
              content: "170mmx79mmx22.6mm",
            },
            {
              name: "整机重量",
              content: "小于300g(依照配置不同有区别)",
            },
          ],
        },
        {
          name: "网络",
          list: [
            {
              name: "网络类型",
              content: "欧亚+国内",
            },
            {
              name: "移动网络",
              content: "支持4G全网通(电信4G、移动4G、联通4G)",
            },
            {
              name: "WLAN功能",
              content: "IEEE 802.11a/b/g/n,支持双频2.4G和5G",
            },
            {
              name: "SIM卡",
              content: "支持双SIM卡",
            },
            {
              name: "卡槽类型",
              content: "两个Nano-SIM卡和一个 TF卡",
            },
            {
              name: "TF卡",
              content: "支持(SIM卡与TF二选一)",
            },
          ],
        },
        {
          name: "NFC",
          list: [
            {
              name: "工作频率",
              content: "13.56MHz",
            },
            {
              name: "读卡类型",
              content:
                "ISO/IEC14443A、14443B、Mifare等非接触卡TypeA卡/ TypeB卡及电子标签,包含身份证、港澳台居民居住证、外国人永久居留证、公交卡、市政卡、老年卡、学生卡、护照、elD电子证件等",
            },
            {
              name: "读写距离",
              content: "0-3cm",
            },
          ],
        },
        {
          name: "LCM",
          list: [
            {
              name: "LCD",
              content: "5.5英寸IPS高清屏,HD分辨率(720*1440)",
            },
            {
              name: "TP",
              content:
                "电容屏,多点触摸,支持湿手、手套操作,采用防油渍指纹涂层",
            },
          ],
        },
        {
          name: "数据采集",
          list: [
            {
              name: "摄像头",
              content: "后置1600万像素彩色摄像头,人脸识别距离:0.2-2m",
            },
            {
              name: "条码采集",
              content:
                "支持一维码条码类型:\nUPC/EAN,Code128,Code39,Code93,Code11,Interleaved2of5,Discrete2of5,Chinese2of5,Codabar,MSI,RSS等\n支持二维码条码类型:\nCode39,Code128,PDF417,MicroPDF417,Datamatrix,Composite,RSS,TLC39,QRcode,MicroQRcode,Aztec,MaxiCode;Post",
            },
          ],
        },
        {
          name: "使用环境",
          list: [
            {
              name: "工作温度",
              content: "-10°C至50°C",
            },
            {
              name: "储存温度",
              content: "-40°C至70°C",
            },
            {
              name: "环境温度",
              content: "5%RH-95%RH (无凝结)",
            },
          ],
        },
      ],
      show1: false,
      show2: false,
      show3: false,
    };
  },
  directives: {
    ScrollTo: VueScrollTo.directive,
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  beforeDestroy() {
    windowNaNpxoveEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 获取页面滚动距离
    handleScroll() {
      const element = this.$refs.targetElement;
      const element2 = this.$refs.targetElement2;
      const element3 = this.$refs.targetElement3;
      const rect = element.getBoundingClientRect();
      const rect2 = element2.getBoundingClientRect();
      const rect3 = element3.getBoundingClientRect();

      if (rect.top <= window.innerHeight && rect.bottom <= 1200) {
        if (!this.show1) {
          this.show1 = true;
        }
      }

      if (rect2.top <= window.innerHeight && rect2.bottom <= 1200) {
        if (!this.show2) {
          this.show2 = true;
        }
      }
      if (rect3.top <= window.innerHeight && rect3.bottom <= 1200) {
        if (!this.show3) {
          this.show3 = true;
        }
      }
    },
  },
};
</script>

<style  scoped>
.detail_item {
  width: 100%;
  height: 36.6875rem;
  position: relative;
}
.detail_item2 {
  width: 100%;
  height: 33.125rem;
  position: relative;
}
.detail_img {
  width: 100%;
  height: 100%;
}
.detail_text {
  position: absolute;
  bottom: 3.3125rem;
  left: 0;
  width: 100%;
  opacity: 0;
}
.detail_title {
  font-size: 1.6875rem;
  color: white;
  font-weight: bold;
}
.detail_tips {
  margin-top: 1.1875rem;
  color: white;
  font-size: 0.9375rem;
}
.detail_text2 {
  position: absolute;
  left: 1.9375rem;
  top: 2.6875rem;
  opacity: 0;
}
.detail_title2 {
  font-size: 1.6875rem;
  color: #000;
  font-weight: bold;
  text-align: left;
}
.detail_tips2 {
  font-size: 0.9375rem;
  color: #000;
  width: 19.5rem;
  margin-top: 1.1875rem;
  text-align: left;
}
.detail_ul {
  position: absolute;
  bottom: 3.625rem;
  left: 2.75rem;
  opacity: 0;
}
.detail_li {
  font-size: 0.875rem;
  color: #333333;
  margin-top: 0.9375rem;
  text-align: left;
  position: relative;
}
.detail_li::after {
  content: "";
  left: -0.9375rem;
  top: 50%;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  position: absolute;
  background: #333333;
  transform: translateY(-50%);
}
.detail_text3 {
  position: absolute;
  top: 3.875rem;
  left: 0;
  opacity: 0;
  width: 100%;
}
.detail_text4 {
  position: absolute;
  top: 3.875rem;
  left: 0;
  opacity: 0;
  width: 100%;
}
.detail_title3 {
  font-size: 1.6875rem;
  color: #000000;
  font-weight: bold;
}
.detail_tips3 {
  font-size: 0.9375rem;
  margin: 1.1875rem auto 0;
  color: #000;
  text-align: center;
  width: 19.25rem;
}
.detail_title4 {
  font-size: 1.6875rem;
  color: white;
  font-weight: bold;
}
.detail_tips4 {
  margin-top: 1.125rem;
  font-size: 0.9375rem;
  color: white;
}
.parameter {
  margin-top: 2.625rem;
}
.parameter_title {
  font-size: 1.4375rem;
  color: #000;
  font-weight: bold;
  padding-left: 1.3125rem;
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 1.3125rem;
}
.parameter_tab {
  width: 100%;
  height: 3.5625rem;
  background: #fafafa;
  text-align: left;
  font-size: 1.0625rem;
  padding-left: 1.3125rem;
  font-weight: bold;
  color: #000;
  box-sizing: border-box;
  line-height: 3.5625rem;
}
.parameter_item {
  width: 100%;
  padding: 1.3125rem 0;
  display: flex;
  align-items: center;
  border-bottom: 0.0625rem solid #dddddd;
}
.parameter_item_name {
  width: 4.5625rem;
  height: 100%;
  margin-right: 2.625rem;
  margin-left: 1.3125rem;
  box-sizing: border-box;
  text-align: left;
  font-size: 0.9375rem;
  color: #797979;
}
.parameter_item_anw {
  width: 13.75rem;
  word-wrap: break-word;
  white-space: pre-line;
  font-size: 0.9375rem;
  text-align: left;
  color: #333333;
}
</style>