<template>
  <div>
    <div class="banner">
      <img src="@/assets/mobile/about/banner.jpg" />
      <span class="about_title">智能创新 物联生活</span>
    </div>
    <div class="tab">
      <div
        v-for="item in tabList"
        :key="item.index"
        @click="switcTab(item.index)"
        :class="[selectIndex == item.index ? 'selecttab' : 'notab']"
      >
        {{ item.name }}
      </div>
    </div>
    <!-- 公司简介 -->
    <Synopsis v-if="selectIndex == 0" />
    <!-- 发展历程 -->
    <Progress v-if="selectIndex == 1" />
    <!-- 联系我们 -->
    <Contact v-if="selectIndex == 2" />
  </div>
</template>

<script>
import Synopsis from "./synopsis.vue";
import Progress from "./progress.vue";
import Contact from "./contact.vue";
export default {
  data() {
    return {
      selectIndex: 0,
      tabList: [
        {
          name: "公司简介",
          index: 0,
        },
        {
          name: "发展历程",
          index: 1,
        },
        {
          name: "联系我们",
          index: 2,
        },
      ],
    };
  },

  components: {
    Synopsis,
    Progress,
    Contact,
  },

  methods: {
    // 切换tab
    switcTab(index) {
      this.selectIndex = index;
    },
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 26rem;
  position: relative;
}
.banner img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.about_title {
  position: absolute;
  top: 5.6875rem;
  left: 0;
  right: 0;
  font-size: 1.6875rem;
  color: #ffffff;
}
.tab {
  width: 100%;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 2rem auto 2rem;
  color: #000000ff;
}
.tab div {
  cursor: pointer;
}
.selecttab {
  position: relative;
  color: #60a6ec;
}
.selecttab::after {
  content: "";
  width: 2.625rem;
  height: 0.25rem;
  position: absolute;
  background: #60a6ec;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.625rem;
}
.notab {
  color: #000000;
}
</style>
