<template>
  <div>
    <img src="@/assets/solution/img5.png" class="banner" />
    <div class="content">
      <div class="title">方案概述</div>
      <div class="sub">智慧公租房解决方案是拥有丰富的功能与服务内容，方案由智慧公租房管理平台智慧管控终端、智慧租房应用端、云平台基础服务构成，从租房、签约、入住、安全管理，全方位服务租户与租客，提升客户粘性与服务质量。
</div>
    </div>
    <img src="@/assets/solution/img6.png" class="blueprint">
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.banner {
  width: 100%;
  height: 46.25rem;
}
.title{
    font-size: 3.25rem;
    font-weight: bold;
    color: #333333FF;
    text-align: left;
}
.content{
    width: 100%;
    padding: 8.75rem 0 8.75rem 9.125rem;
    box-sizing: border-box;
}
.sub{
    width: 101.25rem;
    margin-top: 2.75rem;
    font-size: 1.25rem;
    text-align: left;
}
.blueprint{
    width: 100%;
    height: 64.25rem
}
</style>